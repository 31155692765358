import { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { ArrowLeft } from 'react-feather';
import { motion } from "framer-motion"


import Pill from '../Pill/Pill';


import size from '../../DesignSystem/size.json';


const HomeLink = ({theme}) => {
  const [isHovered, setHovered] = useState(false);

  const onClick = () => {
    console.log('Home');
  }

  const linkStyle = {
    color: theme.primary,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  return(
    <Link to={'/#'}>
      <div 
        style={linkStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        onClick={onClick}
      >
        <motion.span
          style={{display: 'flex', alignItems: 'center'}}
          animate={{ x: isHovered ? -5 : 0 }}
        >
          <ArrowLeft size={size.iconSize['s']}/>
        </motion.span>
        
        <span>Home</span>
      </div>
    </Link>

  )
}

const BlogHeading = ({theme,title,info}) => {

  const containerStyle = {
    padding: size.spacingTopBottom['m'],
    maxWidth: size.MAX_WIDTH_BLOG,
  }
  const titleStyle = {
    color: theme.text['1'],
    fontSize: size.font_size['xl'],
  }
  

  return(
    <div style={containerStyle}>
      <HomeLink theme={theme}/>
      <h1 style={titleStyle} dangerouslySetInnerHTML= {{__html: title }}></h1>
      {info !== [] && info.map((item, i) => {
        return (
          <span style={{marginRight: size.spacing['s']}} key={i}>
            <Pill theme={theme} type={'info'}>{item}</Pill>
          </span>
          )
      })}
    </div>
  )
}

export default BlogHeading;

