

import PageFrame from "../components/PageFrame/PageFrame";
import PageHeader from '../components/Home/PageHeader/PageHeader';
import Portfolio from '../components/Home/Portfolio/Portfolio';
import Skills from '../components/Home/Skills/Skills';
import Lebenslauf from '../components/Home/Lebenslauf/Lebenslauf';
import Contact from '../components/Home/Contact/Contact';


const Spacer = ({width}) => {//PFUSCH

  let mobileView = false
  if(width < 714){
    mobileView = true
  }

  const divStyle = {
    marginTop: mobileView ? '500px' : '320px'
  }

  return(
    <div style={divStyle}></div>
  )
}


const Home = ({theme,user,language,width,addAlert}) => {

  


  return(
    <div>
    <Spacer width={width}/> 
    <PageHeader theme={theme} language={language}/>
    <Portfolio theme={theme} language={language}/>
    <Skills theme={theme} width={width}/>
    <Lebenslauf theme={theme} width={width} language={language}/>
    <Contact theme={theme} language={language} user={user} addAlert={addAlert}/>
    </div>
  )
}



const HomePage = () => {
  return (
    <PageFrame title={''} navType={'links'}>
      <Home/>
    </PageFrame>
  );
}

export default HomePage;


