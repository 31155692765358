import "./Spinner.css"
const Spinner = ({size}) => {
  const svg_style = {
    maxWidth: '20px',
    maxHeight: '20px',
  }
  return(
    <div style={svg_style}>

    
    <svg viewBox="0 0 100 100" className="spinner_svg" xmlns="http://www.w3.org/2000/svg">
      <circle className="spinner_circle"cx="50" cy="50" r="45"/>
    </svg></div>
  )
}

export default Spinner;