import { Link } from "react-router-dom";

import SectionHeading from "../Headings/SectionHeading";

import size from '../../DesignSystem/size.json';

import reactImg from "../../images/FrameStack/React.svg";
import nextjsImg from "../../images/FrameStack/next-js.svg";
import tailwindImg from "../../images/FrameStack/tailwind.svg";
import shadcnUiImg from "../../images/FrameStack/shadcnUi.png";
import mongoDBImg from "../../images/FrameStack/mongodb.svg";
import influxDBImg from "../../images/FrameStack/influxdb.svg";
import typescriptImg from "../../images/FrameStack/typescript.svg";
import dockerImg from "../../images/FrameStack/docker.svg";
import gitlabImg from "../../images/FrameStack/gitlab.svg";
import cypressImg from "../../images/FrameStack/cypress.svg";

const TechStackData = [
  {
    name: "Frontend",
    items: [
      { name: "react", logo: reactImg, href: "https://reactjs.org/" },
      {
        name: "typescript",
        logo: typescriptImg,
        href: "https://www.typescriptlang.org/",
      },
      { name: "next.js", logo: nextjsImg, href: "https://nextjs.org/" },
      { name: "tailwind", logo: tailwindImg, href: "https://tailwindcss.com/" },
      {
        name: "shadcnUi",
        logo: shadcnUiImg,
        href: "https://ui.shadcn.com/docs/components",
      }, // Keine offizielle Website gefunden.
    ],
  },
  {
    name: "Backend",
    items: [
      {
        name: "typescript",
        logo: typescriptImg,
        href: "https://www.typescriptlang.org/",
      },
      { name: "next.js", logo: nextjsImg, href: "https://nextjs.org/" },
    ],
  },
  {
    name: "Database",
    items: [
      { name: "MongoDB", logo: mongoDBImg, href: "https://www.mongodb.com/" },
      {
        name: "InfluxDB",
        logo: influxDBImg,
        href: "https://www.influxdata.com/",
      },
    ],
  },
  {
    name: "Deployment/Testing",
    items: [
      { name: "Docker", logo: dockerImg, href: "https://www.docker.com/" },
      { name: "cypress", logo: cypressImg, href: "https://www.cypress.io/" },
      {
        name: "Gitlab CICD",
        logo: gitlabImg,
        href: "https://about.gitlab.com/",
      },
    ],
  },
];

const TechstackRow = ({ title, items, theme }) => {
  const titleStyle = {
    color: theme.text["1"],
    marginBottom: size.spacing["s"],
  };
  const rowStyle = {
    color: theme.text["1"],
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  };

  return (
    <div style={{ marginBottom: size.spacing["m"] }}>
      <div style={titleStyle}>{title}:</div>
      <div style={rowStyle}>
        {items.map((item, i) => {
          return (
            <Link to={item.href} target="_blank">
              <div
                style={{
                  textAlign: "center",
                  height: "50px",
                  width: "70px",
                  cursor: "pointer",
                }}
              >
                <div style={{ height: "40px", display: "flex" }}>
                  <img
                    src={item.logo}
                    alt=""
                    style={{
                      width: "40px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  ></img>
                </div>
                <div style={{ fontSize: size.font_size["xs"] }}>
                  {item.name}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const TechStack = ({theme}) => {
  return (
    <div style={{marginBottom:size.spacing['l']}}>
      <SectionHeading title={"Techstack"} theme={theme} font_size={"l"} />
      {TechStackData.map((row, r) => {
        return (
          <TechstackRow title={row.name} items={row.items} theme={theme} />
        );
      })}
    </div>
  );
};
