const HorizontalCenter = ({children,maxWidth}) => {

  const style = {
    maxWidth : maxWidth,
    margin: '0 auto',
  }

  return( 
    <div style={style}>
      {children}
    </div>
  )
}

export default HorizontalCenter;