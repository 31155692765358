import size from '../../DesignSystem/size.json';

const Pill = ({children,theme,type}) => {

  const colorDict = {
    'info': theme.primary,
    'error': theme.error,
    'warning': theme.warning,
    'success': theme.success,
  }

  const pillStyle = {
    backgroundColor: `${colorDict[type]}${theme.opacityModifier['1']}`,
    color: colorDict[type],
    padding: size.spacingLeftRight['s'],
    borderRadius: '100px',
  }

  return(
    <span style={pillStyle}>
      {children}
    </span>
  )
}

export default Pill;

// <Pill theme={theme} type={'info'}>Dadadadadad</Pill>
// <Pill theme={theme} type={'error'}>Dadadadadad</Pill>
// <Pill theme={theme} type={'success'}>Dadadadadad</Pill>
// <Pill theme={theme} type={'warning'}>Dadadadadad</Pill>