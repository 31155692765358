import { useState } from "react"
import { Mail, User, MessageSquare, Send } from 'react-feather';

import mailService from "../../../services/mail"

import Button from "../../Button/Button";
import Input from '../../Input/Input';
import Textarea from '../../Input/Textarea';

import size from '../../../DesignSystem/size.json';
import languageDict from '../../../Content/languageDict.json'



const ContactForm = ({theme,language,user,addAlert}) => {
  const [formName, setFormName] = useState("");
  const [formMail, setFormMail] = useState("");
  const [formText, setFormText] = useState("");
  // const [mailStatus, setMailStatus] = useState("");

  const send = async (event) => {
    event.preventDefault()

    setFormText(".")
    
    let response = ""
    try {
      response = await mailService.sendMail({
        name: formName,
        mail: formMail,
        text: formText,
        username: user ? user.username : null
      })
      if (response.status === 200){
        addAlert({type:'success','message':'Message successfully sent'})
      }
    } catch (exception) {
      addAlert({type:'error','message':'Error while sending the message'})
    }
    // console.log(response);

    return false;
  }; 

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  };
  
  const iconStyle = {
    margin: '6px',
    minWidth: size.iconSize['m']
  };
  
  
  return (
    <form style={formStyle} onSubmit={send} action="">
      <Input type={"text"} placeholder={"Name"} setContent={setFormName} theme={theme} required={true}>
        <User size={size.iconSize['m']} style={iconStyle} />
      </Input>
      <Input type={"email"} placeholder={"Email"} setContent={setFormMail} theme={theme} required={true}>
        <Mail size={size.iconSize['m']} style={iconStyle} />
      </Input>
      <Textarea type={"email"} placeholder={languageDict[language]['message']} setContent={setFormText} theme={theme} required={false}>
        <MessageSquare size={size.iconSize['m']} style={iconStyle} />
      </Textarea>
      <Button emphasis={'high'} theme={theme} type={'submit'} minWidth={size.spacing['xxl']}>
        <Send size={size.iconSize['s']}/>
        <span style={{marginLeft:size.spacing['s']}}>{languageDict[language]['send']}</span>
      </Button>
      {/* <button type="submit">{languageDict[language]['send']}</button> */}

    </form>
  );
};

export default ContactForm;
