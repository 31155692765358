import { ArrowRight} from 'react-feather'; //

import size from '../../DesignSystem/size.json';

const List = ({content,theme,alone=true}) => {
  const wrapperStyle = {
    backgroundColor: alone ? theme.bg_variation : '',
    padding: alone ?size.spacing['s'] : '0px',
    margin: alone ? size.spacingTopBottom['m'] : '0px',
    border: alone ? `1px solid ${theme.hairline}`: 'none',
    borderRadius: '4px',
    color: theme.text['2'],
  }
  const listStyle = {
    listStyleType: 'none'
  }
  const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: size.spacingTopBottom['s'],
  }
  const arrowStyle = {
    color: theme.primary,
    marginRight: size.spacing['m']
  }
  return (
    <div style={wrapperStyle}>
      <ul style={listStyle}>
        {content.map((item,i) => <li style={itemStyle} key={i}><ArrowRight size={size.iconSize['m']} style={arrowStyle}/><span style={{maxWidth:'calc(100% - 50px)'}} dangerouslySetInnerHTML= {{__html: item }} ></span></li>)}
      </ul>
    </div>
  )
}

export default List
