import { useRef, useEffect } from "react"; //useEffect
import { Stage, Layer,Line } from 'react-konva';




const DrawCanvas = ({theme, canvas_size, lines, setLines}) => {
  const isDrawing = useRef(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);


  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y] }]);
  };
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };
  const handleMouseUp = () => {
    isDrawing.current = false;
  };


  const canvas_style = {
    margin: `0 auto`,
    border: `1px solid ${theme.hairline}`,
    height: canvas_size,
    width: canvas_size,
    overflow: 'hidden',
    backgroundColor: theme.bg_variation,
  };


  return (
    <div style={canvas_style} >
   
    <Stage 
      className="canvas" 
      width={canvas_size}
      height={canvas_size}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}

      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseUp}

    >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={theme.primary}
              strokeWidth={5}
              tension={0}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default DrawCanvas;