
import size from '../../../DesignSystem/size.json';
import fill from '../../../images/fill.png'
import homeContent from '../../../Content/homeContent.json'


const PageHeader = ({theme,language}) => {

  const headerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'center',
    background: theme.gradient,
    position: 'absolute',
    width: '100%',
    left: '0',
    top: size.nav.height,
    padding: size.spacing['l'],
  }
  const colStyle = {
    margin: size.spacingTopBottom['m'],
  }
  const quoteStyle = {
    color: 'white', //Token
    fontSize: size.font_size['l'],
    maxWidth: '400px',
  }
  const quoteNameStyle = {
    color: '#FFFFFFCC',
    fontSize: size.font_size['s']
  }
  const profileStyle = {
    width: '250px',
    height: '250px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }

  return(
    <div>
    <div style={headerStyle}>
      <div style={colStyle}>
        <div style={quoteStyle}>{homeContent[language]['quote']}</div>
        <div style={quoteNameStyle}>- Mark Twain</div>
      </div>
      <div style={colStyle}>
        <img src={fill} style={profileStyle} alt="logo"/>
      </div>
      
    </div>
    <span id='portfolio' ></span>
    </div>
  )
}

export default PageHeader;