import { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import { HashLink as Link } from 'react-router-hash-link';
import { ArrowRight,Twitter,Mail,Linkedin,Globe } from 'react-feather'; //
import { Tooltip } from 'react-tooltip'

import navigation from '../../Content/navigation.json';
import languageDict from '../../Content/languageDict.json'
import size from '../../DesignSystem/size.json';


import Modal from '../Modal/Modal';
import IconBtn from '../Button/IconButton';
import ScrollProgressNav from './ScrollProgressNav';
import BurgerMenu from '../Animations/Icons/BurgerMenu';
import ThemeSwitcher from '../Button/ThemeSwitcher';
import LogInOut from '../Animations/Icons/LogInOut';
import UnderlineHover from '../Animations/UnderlineHover';
import Logo from '../../images/Logo';


const NavModalLink = ({theme,name,icon,children}) => {
  const [isHovered, setHovered] = useState(false);

  const glowingRowStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: isHovered ? theme.primary: theme.bg,
    color: isHovered ? theme.text['1']: theme.text['2'],
    padding: size.spacing['s'],
  };
  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row', justifyContent: 'space-between',
    backgroundColor: theme.bg,
    color: theme.text['2'],
    padding: size.spacing['s'],
  };
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    padding:size.spacingLeftRight['m']
  }


  if(icon===''){
    return(
    <div style={rowStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <span style={{padding:size.spacingLeftRight['l']}}>{name}</span>
        <span style={{padding:size.spacingLeftRight['l']}}>{children}</span>
        
      </div>
    )
  } else {
    return(
      <div style={glowingRowStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      > 
        <span style={iconStyle}>
          {icon==='Arrow' && <ArrowRight size={20} />}
          {icon==='Mail' && <Mail size={20}/>}
          {icon==='Twitter' && <Twitter size={20}/>}
          {icon==='LinkedIn' && <Linkedin size={20}/>}
        </span>
        <span style={iconStyle}>{name}</span>
      </div>
    )
  }
}


const Navbar = ({ type,title,theme,themeName,setThemeName,scrollProgressY,user,handleLogInOut,language,setLanguage,width}) => {
  const [navModalIsOpen, setNavModalOpen] = useState(false);
  

  const toggleNavMenu = () => {
    setNavModalOpen(!navModalIsOpen)
  }
  const handleThemeChange = () => {
    setThemeName(themeName==='dark' ? 'light' : 'dark')
    window.localStorage.setItem(
      'theme', JSON.stringify(themeName==='dark' ? 'light' : 'dark')
    ) 
  }
  const handleLanguageChange = () => {
    if(language==='GER') {
      setLanguage('ENG')
      window.localStorage.setItem(
        'language', JSON.stringify('ENG')
      ) 
    }
    else if(language==='ENG') {
      setLanguage('GER')
      window.localStorage.setItem(
        'language', JSON.stringify('GER')
      ) 
    }
  }
  
  let mobileView = false
  if(width < 1200){
    mobileView = true
  }

  const navbarStyle = {
    backgroundColor: `${theme.bg}CC`,
    color: theme.text['1'],
    position: "fixed",
    top: "0",
    left: '0',
    width: "100%",
    height: size.nav.height,
    zIndex: "9998",
    padding: '0px',
    margin: '0px',
    backdropFilter: 'blur(3px)',
    // borderBottom: '1px solid', //Fortschrittsanzeige,

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'center',
  }

  // const logoStyle = {
  //   height: '64px',
  // }
  
  const navUlStyle = {
    listStyleType: 'none',
    padding: size.spacingLeftRight['m'],
    // border: '1px solid red'
  }

  const navBtnsStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'center',
    // border: '1px solid red'
  }
  const navTitleStyle = {
    color: theme.text['1'],
    fontSize: size.font_size["l"],
    fontWeight: size.font_weight['m'],
  }
  const navLiStyle = {
    float: 'left',
    display: 'block',
    padding: size.spacingLeftRight['s'],
    // paddingBottom: '0px',
    cursor: 'pointer',
  }
  const navModalStyle = {
    maxHeight: '500px',
    minWidth: mobileView ? '280px'  : '600px',
    margin: size.spacing['m'],
    zIndex: 1001,
    backgroundColor: theme.bg,
    color: theme.text['2'],
    overflowY: 'auto',
    borderRadius: '4px',
    paddingBottom: size.spacing['m'],
    border: `1px solid ${theme.hairline}`,
  };
  const modalHeadStyle = {
    padding: size.spacing['m'],
    backgroundColor: theme.bg_variation,
  };

  const logoDivStyle = {minWidth: '0px', cursor:'pointer'}
  if(type==='title'){
    logoDivStyle.minWidth='70px'
    navBtnsStyle.minWidth='70px'
  } else if(type==='links' && !mobileView) {
    logoDivStyle.minWidth='168px'
    navBtnsStyle.minWidth='168px'
  }

  return (
    <>
    <Modal theme={theme} isOpen={navModalIsOpen} toggleOpen={toggleNavMenu}>
      <div style={navModalStyle}>
        <div style={modalHeadStyle}>ShortCuts</div>

        <NavModalLink theme={theme} name={user ?'LogOut' : 'LogIn'} icon={''}><IconBtn theme={theme} hoverColor={theme.primary} onClick={handleLogInOut}><LogInOut user={user}/></IconBtn></NavModalLink>
        <NavModalLink theme={theme} name={languageDict[language]['switchTheme']} icon={''}><IconBtn theme={theme} hoverColor={theme.primary} onClick={handleThemeChange} ><ThemeSwitcher themeName={themeName}/></IconBtn></NavModalLink>
        <NavModalLink theme={theme} name={languageDict[language]['switchLanguage']} icon={''}><IconBtn theme={theme} hoverColor={theme.primary} onClick={handleLanguageChange} ><Globe/></IconBtn></NavModalLink>
        
        <div style={modalHeadStyle}>Navigation</div>
        {navigation['internal'].map((link,i) => {
          return(
            // <Link to={link.link} onClick={toggleNavMenu} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -50) }}>
            //   <NavModalLink theme={theme} name={link.name} icon={'Arrow'} key={i}/>
            // </Link>
            <Link to={link.link} onClick={toggleNavMenu} smooth key={i}>
              <NavModalLink theme={theme} name={link.name[language]} icon={'Arrow'} />
            </Link>
          )
        })}
        <div style={modalHeadStyle}>Links</div>
        {navigation['external'].map((link,i) => {
          return(
            <Link to={link.link} key={i}>
              <NavModalLink theme={theme} name={link.name} icon={link.name}/>
            </Link>
          )
        })}
      </div>
    </Modal>

    <div style={navbarStyle}>
      <div style={logoDivStyle}>
        <Link to={'/#'}> 
          <Logo size='50px' theme={theme}/>
          {/* <img src={logo} style={logoStyle} alt="logo"/> */}
        </Link>
      </div>
      
      <ul style={navUlStyle}>
        {(type==='links' && !mobileView) && navigation['internal'].map((link,i) => {
          return(
          <Link to={link.link} smooth key={i}>
              <li style={navLiStyle}>
                <UnderlineHover color={theme.text['1']}>
                  {link.name[language]}
                </UnderlineHover>
              </li>
          </Link>
          )
        })}
        {(type==='title' && !mobileView) &&
          <span style={navTitleStyle}>{languageDict[language][title]}</span>
        }
      </ul>
      <div style={navBtnsStyle}>
        {(!mobileView && type==='links') && <span id="LoginBtn"><IconBtn theme={theme} hoverColor={theme.primary} navModalIsOpen={navModalIsOpen} onClick={handleLogInOut}><LogInOut user={user}/></IconBtn></span>}
        {(!mobileView && type==='links') && <span id="ThemeBtn"><IconBtn theme={theme} hoverColor={theme.primary} navModalIsOpen={navModalIsOpen} onClick={handleThemeChange}><ThemeSwitcher themeName={themeName}/></IconBtn></span>}
        {(!mobileView && type==='links') && <span id="languageBtn"><IconBtn theme={theme} hoverColor={theme.primary} navModalIsOpen={navModalIsOpen} onClick={handleLanguageChange}><Globe/></IconBtn></span>}
        {(mobileView || type==='title') && <IconBtn theme={theme} hoverColor={theme.primary} onClick={toggleNavMenu}><BurgerMenu theme={theme} isOpen={navModalIsOpen} size={size.iconSize['m']}/></IconBtn>}
      </div>
      <Tooltip
        anchorId="LoginBtn"
        place="bottom"
        content={user ?'LogOut' : 'LogIn'}
        variant={theme['tooltip']}
      />
      <Tooltip
        anchorId="ThemeBtn"
        place="bottom"
        content={languageDict[language]['switchTheme']}
        variant={theme['tooltip']}
      />
      <Tooltip
        anchorId="languageBtn"
        place="bottom"
        content={languageDict[language]['switchLanguage']}
        variant={theme['tooltip']}
      />
    </div>
    <ScrollProgressNav theme={theme} scrollProgressY={scrollProgressY}/>
    </>
  );
}

export default Navbar;