import { Info, AlertOctagon, AlertTriangle, CheckCircle } from 'react-feather';


import size from '../../DesignSystem/size.json';


const IconCard = ({theme,type,children}) => {

  const colorDict = {
    'info': theme.primary,
    'error': theme.error,
    'warning': theme.warning,
    'success': theme.success,
  } 

  const cardStyle = {
    // boxShadow:'2px 2px 10px 1px #000000',
    borderRadius: '4px',
    border: `1px solid ${theme.hairline}`,
    // backgroundColor: theme.mask['mask_2'],
    // margin: size.spacing['m'], 
    margin: size.spacingTopBottom['l'], 
    padding: size.spacing['m'],
    position: 'relative',
    backgroundColor: type==='info'? theme.bg_variation:  `${theme[type]}${theme.opacityModifier['5']}`,
  }
  const bubbleStyle = {
    position: 'absolute',
    color: theme.bg,
    backgroundColor: colorDict[type],
    height: size.iconSize["l"],
    width: size.iconSize["l"],
    top: '-16px',
    right: '-16px',
    borderRadius: '25px',
    textAlign: 'center',
    lineHeight: '42px',
    boxShadow:'2px 2px 10px 1px #000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const bodyStyle = {
    // padding: size.spacing["m"],
    fontSize: size.font_size["s"],
    paddingTop: '0px',
    color: theme.text['1']
  }


  return(
 
    <div style={cardStyle}>
      <div style={bubbleStyle}>
        {type==='info' && <Info/>}
        {type==='success' && <CheckCircle/>}
        {type==='warning' && <AlertTriangle/>}
        {type==='error' && <AlertOctagon/>}
      </div>
      <div style={bodyStyle}>
        {children}
      </div>
    </div>
  )
}

export default IconCard