import { useState } from 'react';
import { Eye, EyeOff } from 'react-feather'; //


import size from '../../DesignSystem/size.json';


const Input = ({theme,children,type,placeholder,setContent,required=false}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const inputStyle = {
    padding: '5px',
    border: 'none',
    width: '100%',
    height: '',
    color: theme.text['1'],
    backgroundColor: theme.bg_variation,
  };
  
  const inputWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: size.spacingTopBottom['s'],
    padding: size.spacing['s'],
    backgroundColor: theme.bg_variation,
    color: theme.text['1'],
    border: `1px solid ${theme.hairline}`,
    borderRadius: '5px',
    width: '100%',
    maxWidth: '800px'
  };
  const hidePasswordBtnStyle = {
    height: '20px',
    width: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.text['3'],
    marginLeft: size.spacing['s']
  };
  
  if(type==='password'){
    return(
      <div style={inputWrapperStyle}>
          {children}
          <input type={passwordVisible ? 'text' : 'password'} style={inputStyle} placeholder={placeholder} onChange={handleContentChange} required={required}/>
          <button style={hidePasswordBtnStyle} onClick={togglePasswordVisibility}>
            {passwordVisible ? <EyeOff size={size.iconSize['s']}/> : <Eye size={size.iconSize['s']}/>}
          </button>
      </div>
    )
  }
  return(
    <div style={inputWrapperStyle}>
        {children}
        <input type={type} style={inputStyle} placeholder={placeholder} onChange={handleContentChange} required={required}/>
    </div>
  )
}

export default Input;