
// https://github.com/timothygebhard/js-colormaps

function interpolated(x, colors) {
  let lo = Math.floor(x * (colors.length - 1));
  let hi = Math.ceil(x * (colors.length - 1));
  let r = Math.round((colors[lo][0] + colors[hi][0]) / 2 * 255);
  let g = Math.round((colors[lo][1] + colors[hi][1]) / 2 * 255);
  let b = Math.round((colors[lo][2] + colors[hi][2]) / 2 * 255);
  return [r, g, b];
}


function evaluate_cmap(x, reverse=false,returnString=false) {
  /**
   * Evaluate colormap `name` at some value `x`.
   * @param {number} x - The value (between 0 and 1) at which to evaluate the colormap.
   * @reverse {boolean} reverse - Whether or not to reverse the colormap.
   * @return {list} - A 3-tuple (R, G, B) containing the color assigned to `x`.
   */

  // Ensure that the value of `x` is valid (i.e., 0 <= x <= 1)
  if (!(0 <= x <= 1)) {
    alert('Illegal value for x! Must be in [0, 1].')
  }

  // We can get the reverse colormap by evaluating colormap(1-x)
  if (reverse === true) {
    x = 1 - x;
  }

  // Get the colors and whether or not we need to interpolate
  let colors = [
    [
      0.267,
      0.0049,
      0.3294
    ],
    [
      0.2685,
      0.0096,
      0.3354
    ],
    [
      0.2699,
      0.0146,
      0.3414
    ],
    [
      0.2713,
      0.0199,
      0.3473
    ],
    [
      0.2726,
      0.0256,
      0.3531
    ],
    [
      0.2738,
      0.0315,
      0.3589
    ],
    [
      0.275,
      0.0378,
      0.3645
    ],
    [
      0.276,
      0.0442,
      0.3702
    ],
    [
      0.277,
      0.0503,
      0.3757
    ],
    [
      0.2779,
      0.0563,
      0.3812
    ],
    [
      0.2788,
      0.0621,
      0.3866
    ],
    [
      0.2796,
      0.0678,
      0.3919
    ],
    [
      0.2803,
      0.0734,
      0.3972
    ],
    [
      0.2809,
      0.0789,
      0.4023
    ],
    [
      0.2814,
      0.0843,
      0.4074
    ],
    [
      0.2819,
      0.0897,
      0.4124
    ],
    [
      0.2823,
      0.095,
      0.4173
    ],
    [
      0.2827,
      0.1002,
      0.4222
    ],
    [
      0.2829,
      0.1054,
      0.4269
    ],
    [
      0.2831,
      0.1106,
      0.4316
    ],
    [
      0.2832,
      0.1157,
      0.4361
    ],
    [
      0.2832,
      0.1208,
      0.4406
    ],
    [
      0.2832,
      0.1258,
      0.445
    ],
    [
      0.2831,
      0.1309,
      0.4492
    ],
    [
      0.2829,
      0.1359,
      0.4534
    ],
    [
      0.2826,
      0.1409,
      0.4575
    ],
    [
      0.2823,
      0.1459,
      0.4615
    ],
    [
      0.2819,
      0.1509,
      0.4654
    ],
    [
      0.2814,
      0.1558,
      0.4692
    ],
    [
      0.2809,
      0.1608,
      0.4729
    ],
    [
      0.2803,
      0.1657,
      0.4765
    ],
    [
      0.2796,
      0.1706,
      0.48
    ],
    [
      0.2788,
      0.1755,
      0.4834
    ],
    [
      0.278,
      0.1804,
      0.4867
    ],
    [
      0.2771,
      0.1852,
      0.4899
    ],
    [
      0.2762,
      0.1901,
      0.493
    ],
    [
      0.2752,
      0.1949,
      0.496
    ],
    [
      0.2741,
      0.1997,
      0.4989
    ],
    [
      0.273,
      0.2045,
      0.5017
    ],
    [
      0.2718,
      0.2093,
      0.5044
    ],
    [
      0.2706,
      0.2141,
      0.5071
    ],
    [
      0.2693,
      0.2188,
      0.5096
    ],
    [
      0.268,
      0.2235,
      0.512
    ],
    [
      0.2666,
      0.2283,
      0.5143
    ],
    [
      0.2651,
      0.233,
      0.5166
    ],
    [
      0.2637,
      0.2376,
      0.5188
    ],
    [
      0.2621,
      0.2423,
      0.5208
    ],
    [
      0.2606,
      0.2469,
      0.5228
    ],
    [
      0.259,
      0.2515,
      0.5247
    ],
    [
      0.2573,
      0.2561,
      0.5266
    ],
    [
      0.2556,
      0.2607,
      0.5283
    ],
    [
      0.2539,
      0.2653,
      0.53
    ],
    [
      0.2522,
      0.2698,
      0.5316
    ],
    [
      0.2504,
      0.2743,
      0.5331
    ],
    [
      0.2486,
      0.2788,
      0.5346
    ],
    [
      0.2468,
      0.2832,
      0.5359
    ],
    [
      0.245,
      0.2877,
      0.5373
    ],
    [
      0.2431,
      0.2921,
      0.5385
    ],
    [
      0.2412,
      0.2965,
      0.5397
    ],
    [
      0.2393,
      0.3009,
      0.5408
    ],
    [
      0.2374,
      0.3052,
      0.5419
    ],
    [
      0.2355,
      0.3095,
      0.5429
    ],
    [
      0.2336,
      0.3138,
      0.5439
    ],
    [
      0.2317,
      0.3181,
      0.5448
    ],
    [
      0.2297,
      0.3224,
      0.5457
    ],
    [
      0.2278,
      0.3266,
      0.5465
    ],
    [
      0.2259,
      0.3308,
      0.5473
    ],
    [
      0.2239,
      0.335,
      0.5481
    ],
    [
      0.222,
      0.3392,
      0.5488
    ],
    [
      0.2201,
      0.3433,
      0.5494
    ],
    [
      0.2181,
      0.3474,
      0.55
    ],
    [
      0.2162,
      0.3515,
      0.5506
    ],
    [
      0.2143,
      0.3556,
      0.5512
    ],
    [
      0.2124,
      0.3597,
      0.5517
    ],
    [
      0.2105,
      0.3637,
      0.5522
    ],
    [
      0.2086,
      0.3678,
      0.5527
    ],
    [
      0.2068,
      0.3718,
      0.5531
    ],
    [
      0.2049,
      0.3757,
      0.5535
    ],
    [
      0.2031,
      0.3797,
      0.5539
    ],
    [
      0.2012,
      0.3837,
      0.5543
    ],
    [
      0.1994,
      0.3876,
      0.5546
    ],
    [
      0.1976,
      0.3915,
      0.555
    ],
    [
      0.1959,
      0.3954,
      0.5553
    ],
    [
      0.1941,
      0.3993,
      0.5556
    ],
    [
      0.1924,
      0.4032,
      0.5558
    ],
    [
      0.1906,
      0.4071,
      0.5561
    ],
    [
      0.1889,
      0.4109,
      0.5563
    ],
    [
      0.1872,
      0.4147,
      0.5565
    ],
    [
      0.1856,
      0.4186,
      0.5568
    ],
    [
      0.1839,
      0.4224,
      0.5569
    ],
    [
      0.1823,
      0.4262,
      0.5571
    ],
    [
      0.1806,
      0.43,
      0.5573
    ],
    [
      0.179,
      0.4338,
      0.5574
    ],
    [
      0.1774,
      0.4375,
      0.5576
    ],
    [
      0.1758,
      0.4413,
      0.5577
    ],
    [
      0.1743,
      0.445,
      0.5578
    ],
    [
      0.1727,
      0.4488,
      0.5579
    ],
    [
      0.1712,
      0.4525,
      0.558
    ],
    [
      0.1696,
      0.4563,
      0.558
    ],
    [
      0.1681,
      0.46,
      0.5581
    ],
    [
      0.1666,
      0.4637,
      0.5581
    ],
    [
      0.1651,
      0.4674,
      0.5581
    ],
    [
      0.1636,
      0.4711,
      0.5581
    ],
    [
      0.1621,
      0.4748,
      0.5581
    ],
    [
      0.1607,
      0.4785,
      0.5581
    ],
    [
      0.1592,
      0.4822,
      0.5581
    ],
    [
      0.1577,
      0.4859,
      0.558
    ],
    [
      0.1563,
      0.4896,
      0.5579
    ],
    [
      0.1548,
      0.4933,
      0.5578
    ],
    [
      0.1534,
      0.497,
      0.5577
    ],
    [
      0.1519,
      0.5007,
      0.5576
    ],
    [
      0.1505,
      0.5044,
      0.5574
    ],
    [
      0.149,
      0.5081,
      0.5572
    ],
    [
      0.1476,
      0.5117,
      0.557
    ],
    [
      0.1462,
      0.5154,
      0.5568
    ],
    [
      0.1448,
      0.5191,
      0.5566
    ],
    [
      0.1433,
      0.5228,
      0.5563
    ],
    [
      0.1419,
      0.5265,
      0.556
    ],
    [
      0.1405,
      0.5301,
      0.5557
    ],
    [
      0.1391,
      0.5338,
      0.5553
    ],
    [
      0.1378,
      0.5375,
      0.5549
    ],
    [
      0.1364,
      0.5412,
      0.5545
    ],
    [
      0.1351,
      0.5449,
      0.554
    ],
    [
      0.1337,
      0.5485,
      0.5535
    ],
    [
      0.1324,
      0.5522,
      0.553
    ],
    [
      0.1312,
      0.5559,
      0.5525
    ],
    [
      0.1299,
      0.5596,
      0.5519
    ],
    [
      0.1287,
      0.5633,
      0.5512
    ],
    [
      0.1276,
      0.5669,
      0.5506
    ],
    [
      0.1265,
      0.5706,
      0.5498
    ],
    [
      0.1254,
      0.5743,
      0.5491
    ],
    [
      0.1244,
      0.578,
      0.5483
    ],
    [
      0.1235,
      0.5817,
      0.5474
    ],
    [
      0.1226,
      0.5854,
      0.5466
    ],
    [
      0.1218,
      0.5891,
      0.5456
    ],
    [
      0.1211,
      0.5927,
      0.5446
    ],
    [
      0.1206,
      0.5964,
      0.5436
    ],
    [
      0.1201,
      0.6001,
      0.5425
    ],
    [
      0.1197,
      0.6038,
      0.5414
    ],
    [
      0.1195,
      0.6075,
      0.5402
    ],
    [
      0.1194,
      0.6111,
      0.539
    ],
    [
      0.1195,
      0.6148,
      0.5377
    ],
    [
      0.1197,
      0.6185,
      0.5363
    ],
    [
      0.1201,
      0.6222,
      0.5349
    ],
    [
      0.1206,
      0.6258,
      0.5335
    ],
    [
      0.1214,
      0.6295,
      0.532
    ],
    [
      0.1223,
      0.6332,
      0.5304
    ],
    [
      0.1234,
      0.6368,
      0.5288
    ],
    [
      0.1248,
      0.6405,
      0.5271
    ],
    [
      0.1263,
      0.6441,
      0.5253
    ],
    [
      0.1281,
      0.6477,
      0.5235
    ],
    [
      0.1301,
      0.6514,
      0.5216
    ],
    [
      0.1323,
      0.655,
      0.5197
    ],
    [
      0.1347,
      0.6586,
      0.5176
    ],
    [
      0.1373,
      0.6623,
      0.5156
    ],
    [
      0.1402,
      0.6659,
      0.5134
    ],
    [
      0.1433,
      0.6695,
      0.5112
    ],
    [
      0.1466,
      0.673,
      0.5089
    ],
    [
      0.1501,
      0.6766,
      0.5066
    ],
    [
      0.1539,
      0.6802,
      0.5042
    ],
    [
      0.1579,
      0.6838,
      0.5017
    ],
    [
      0.162,
      0.6873,
      0.4991
    ],
    [
      0.1664,
      0.6909,
      0.4965
    ],
    [
      0.1709,
      0.6944,
      0.4938
    ],
    [
      0.1757,
      0.6979,
      0.491
    ],
    [
      0.1807,
      0.7014,
      0.4882
    ],
    [
      0.1858,
      0.7049,
      0.4853
    ],
    [
      0.1911,
      0.7084,
      0.4823
    ],
    [
      0.1966,
      0.7118,
      0.4792
    ],
    [
      0.2022,
      0.7153,
      0.4761
    ],
    [
      0.208,
      0.7187,
      0.4729
    ],
    [
      0.214,
      0.7221,
      0.4696
    ],
    [
      0.2201,
      0.7255,
      0.4662
    ],
    [
      0.2264,
      0.7289,
      0.4628
    ],
    [
      0.2328,
      0.7322,
      0.4593
    ],
    [
      0.2394,
      0.7356,
      0.4557
    ],
    [
      0.2461,
      0.7389,
      0.452
    ],
    [
      0.2529,
      0.7422,
      0.4483
    ],
    [
      0.2599,
      0.7455,
      0.4445
    ],
    [
      0.2669,
      0.7488,
      0.4406
    ],
    [
      0.2741,
      0.752,
      0.4366
    ],
    [
      0.2815,
      0.7552,
      0.4326
    ],
    [
      0.2889,
      0.7584,
      0.4284
    ],
    [
      0.2965,
      0.7616,
      0.4242
    ],
    [
      0.3041,
      0.7647,
      0.4199
    ],
    [
      0.3119,
      0.7678,
      0.4156
    ],
    [
      0.3198,
      0.7709,
      0.4112
    ],
    [
      0.3278,
      0.774,
      0.4066
    ],
    [
      0.3359,
      0.777,
      0.402
    ],
    [
      0.3441,
      0.78,
      0.3974
    ],
    [
      0.3524,
      0.783,
      0.3926
    ],
    [
      0.3607,
      0.786,
      0.3878
    ],
    [
      0.3692,
      0.7889,
      0.3829
    ],
    [
      0.3778,
      0.7918,
      0.3779
    ],
    [
      0.3864,
      0.7946,
      0.3729
    ],
    [
      0.3952,
      0.7975,
      0.3678
    ],
    [
      0.404,
      0.8003,
      0.3626
    ],
    [
      0.4129,
      0.803,
      0.3573
    ],
    [
      0.4219,
      0.8058,
      0.3519
    ],
    [
      0.431,
      0.8085,
      0.3465
    ],
    [
      0.4401,
      0.8111,
      0.341
    ],
    [
      0.4494,
      0.8138,
      0.3354
    ],
    [
      0.4587,
      0.8164,
      0.3297
    ],
    [
      0.4681,
      0.8189,
      0.324
    ],
    [
      0.4775,
      0.8214,
      0.3182
    ],
    [
      0.487,
      0.8239,
      0.3123
    ],
    [
      0.4966,
      0.8264,
      0.3064
    ],
    [
      0.5063,
      0.8288,
      0.3004
    ],
    [
      0.516,
      0.8312,
      0.2943
    ],
    [
      0.5258,
      0.8335,
      0.2881
    ],
    [
      0.5356,
      0.8358,
      0.2819
    ],
    [
      0.5455,
      0.838,
      0.2756
    ],
    [
      0.5555,
      0.8403,
      0.2693
    ],
    [
      0.5655,
      0.8424,
      0.2629
    ],
    [
      0.5756,
      0.8446,
      0.2564
    ],
    [
      0.5857,
      0.8467,
      0.2499
    ],
    [
      0.5958,
      0.8487,
      0.2433
    ],
    [
      0.606,
      0.8507,
      0.2367
    ],
    [
      0.6163,
      0.8527,
      0.2301
    ],
    [
      0.6266,
      0.8546,
      0.2234
    ],
    [
      0.6369,
      0.8565,
      0.2166
    ],
    [
      0.6473,
      0.8584,
      0.2099
    ],
    [
      0.6576,
      0.8602,
      0.2031
    ],
    [
      0.6681,
      0.862,
      0.1963
    ],
    [
      0.6785,
      0.8637,
      0.1895
    ],
    [
      0.6889,
      0.8654,
      0.1827
    ],
    [
      0.6994,
      0.8671,
      0.176
    ],
    [
      0.7099,
      0.8688,
      0.1693
    ],
    [
      0.7204,
      0.8704,
      0.1626
    ],
    [
      0.7309,
      0.8719,
      0.156
    ],
    [
      0.7414,
      0.8734,
      0.1496
    ],
    [
      0.7519,
      0.875,
      0.1432
    ],
    [
      0.7624,
      0.8764,
      0.1371
    ],
    [
      0.7729,
      0.8779,
      0.1311
    ],
    [
      0.7833,
      0.8793,
      0.1254
    ],
    [
      0.7938,
      0.8807,
      0.12
    ],
    [
      0.8042,
      0.882,
      0.115
    ],
    [
      0.8146,
      0.8834,
      0.1103
    ],
    [
      0.8249,
      0.8847,
      0.1062
    ],
    [
      0.8353,
      0.886,
      0.1026
    ],
    [
      0.8456,
      0.8873,
      0.0997
    ],
    [
      0.8558,
      0.8886,
      0.0975
    ],
    [
      0.866,
      0.8899,
      0.096
    ],
    [
      0.8762,
      0.8911,
      0.0952
    ],
    [
      0.8863,
      0.8924,
      0.0954
    ],
    [
      0.8963,
      0.8936,
      0.0963
    ],
    [
      0.9063,
      0.8949,
      0.0981
    ],
    [
      0.9162,
      0.8961,
      0.1007
    ],
    [
      0.9261,
      0.8973,
      0.1041
    ],
    [
      0.9359,
      0.8986,
      0.1081
    ],
    [
      0.9456,
      0.8998,
      0.1128
    ],
    [
      0.9553,
      0.9011,
      0.1181
    ],
    [
      0.9649,
      0.9023,
      0.1239
    ],
    [
      0.9744,
      0.9036,
      0.1302
    ],
    [
      0.9839,
      0.9049,
      0.1369
    ],
    [
      0.9932,
      0.9062,
      0.1439
    ]
  ]

  if(returnString){
    const c = interpolated(x, colors)
    return `rgb(${c[0]},${c[1]},${c[2]})`
  }

  return interpolated(x, colors);
}

export default evaluate_cmap

