
import size from '../DesignSystem/size.json';
import languageDict from '../Content/languageDict.json'
import wahlkreisContent from '../Content/wahlkreisContent.json'



import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import Paragraph from '../components/Text/Paragraph';
import Button from '../components/Button/Button';
import PageFrame from '../components/PageFrame/PageFrame';
import List from '../components/List/List';


const Wahlkreis = ({theme,user,language,width,addAlert}) => {

  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: size.spacing['l'],
    marginBottom: size.spacing['xl'],
    // marginBottom: '0px',
  };

  return(
    <>
    {/* <ContentNav items={wahlkreisContent[language]['contentNavItems']} theme={theme} width={width}/> */}
    <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
      <div id="scroll-container" style={{margin: size.spacing['s']}}>
        <section id={wahlkreisContent[language]['contentNavItems'][0]}>
          <BlogHeading title={wahlkreisContent[language]['title']} info={wahlkreisContent[language]['infoPills']}  theme={theme}/>
          <Paragraph theme={theme} >{wahlkreisContent[language]['intro']}</Paragraph>
          <Paragraph theme={theme} >{wahlkreisContent[language]['law']}</Paragraph>
          <List content={wahlkreisContent[language]['paragraphList']} theme={theme}/>
          <Paragraph theme={theme} >{wahlkreisContent[language]['column_gen_1']}</Paragraph>
          <Paragraph theme={theme} >{wahlkreisContent[language]['column_gen_2']}</Paragraph>
          <div style={btn_rowStyle}>
            <Button link={'http://jl-kramer.de/Wahlkreise/code/'} newTab={true} emphasis={'high'} theme={theme}> Jupiter Notebook </Button>
          </div>
        </section>
      </div>

    </HorizontalCenter>
    </>


  )
}

const WahlkreisPage = () => {


  return (
    <PageFrame title={'Wahlkreiseinteilung'} navType={'title'}>
       <Wahlkreis/>
    </PageFrame>

  );
}

export default WahlkreisPage;
