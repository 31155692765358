/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/python/pMedian'

const solve = async (points,p) => {
  const payload = { points }
  const response = await axios.post(`${baseUrl}/${p}`, payload);
  return response
}

export default { solve }