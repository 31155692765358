import { useState, useEffect } from 'react'


import sprpService from "../services/sprp_mep"

import size from '../DesignSystem/size.json';

import sprpmedContent from '../Content/sprpmedContent.json'
import languageDict from '../Content/languageDict.json'

import ContentNav from '../components/ContentNav/ContentNav';
import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import SectionHeading from '../components/Headings/SectionHeading';
import Paragraph from '../components/Text/Paragraph';
import CodeSnippet from '../components/CodeSnippet/CodeSnippet';
import Button from '../components/Button/Button';
import IconCard from '../components/Cards/IconCard';
import PageFrame from '../components/PageFrame/PageFrame';
import Spinner from '../components/Animations/Spinner/Spinner';
import WarehouseMed from '../components/Warehouse/WarehouseMed';
import Lines from '../components/Plots/Lines';
import ExpandableCard from '../components/Cards/ExpandableCard';
import List from '../components/List/List';
import Heatmap from '../components/Plots/Heatmap';
import Link from '../components/Link/Link'



const SPRPmep = ({theme,user,language,width,addAlert,removeAlert}) => {

  const exactSolutionTimePlot = {
    xLabel: {
      name: sprpmedContent[language]['xLabelExactSolutionTimePlot'],
      offset: -8
    },
    yLabel: {
      name: sprpmedContent[language]['yLabelExactSolutionTimePlot'],
      offset: 0
    },
    xUnit: '',
    yUnit: '',
    lines: [
      {
        name:'solution_time',
        color: '#0E99F0',
      }
    ],
    data: [
      {x: 2, solution_time: 31.749},
      {x: 4, solution_time: 43.222},
      {x: 6, solution_time: 55.487},
      {x: 8, solution_time: 66.244},
      {x: 10, solution_time: 77.584},
      {x: 12, solution_time: 85.388},
      {x: 14, solution_time: 92.828},
      {x: 16, solution_time: 98.478},
      {x: 18, solution_time: 102.172},
      {x: 20, solution_time: 104.545},
      {x: 22, solution_time: 103.071},
      {x: 24, solution_time: 104.035},
      {x: 26, solution_time: 104.586},
      {x: 28, solution_time: 102.884},
      {x: 30, solution_time: 99.555},
      {x: 32, solution_time: 99.033},
      {x: 34, solution_time: 95.401},
      {x: 36, solution_time: 91.036},
      {x: 38, solution_time: 90.601},
      {x: 40, solution_time: 86.893},
      {x: 42, solution_time: 82.139},
      {x: 44, solution_time: 80.191},
      {x: 46, solution_time: 75.870},
      {x: 48, solution_time: 73.645},
      {x: 50, solution_time: 70.698},
      {x: 52, solution_time: 68.991},
      {x: 54, solution_time: 66.694},
      {x: 56, solution_time: 64.148},
      {x: 58, solution_time: 63.125},
      {x: 60, solution_time: 61.440},
      {x: 62, solution_time: 61.575},
      {x: 64, solution_time: 59.993},
      {x: 66, solution_time: 57.934},
      {x: 68, solution_time: 58.046},
      {x: 70, solution_time: 56.892},
    ],
    margin: {
      top: 20,
      right: 25,
      left: 20,
      bottom: 20
    },
    legend: {}
  }
  const evalLossPappePlot = {
    xLabel: {
      name: 'epoch',
      offset: -8
    },
    yLabel: {
      name: 'MSE',
      offset: 0
    },
    xUnit: '',
    yUnit: '',
    lines: [
      {
        name:'test_loss',
        color: '#0E99F0',
      },
      {
        name:'train_loss',
        color: '#0ef065',
      },
    ],
    data: [
      {x: 1.0,train_loss: 22.013636,test_loss: 22.591284},
      {x: 2.0,train_loss: 11.864907,test_loss: 12.194184},
      {x: 3.0,train_loss: 8.614848,test_loss: 8.93676},
      {x: 4.0,train_loss: 7.005406,test_loss: 7.3592343},
      {x: 5.0,train_loss: 5.6503596,test_loss: 6.0083094},
      {x: 6.0,train_loss: 5.2360153,test_loss: 5.639258},
      {x: 7.0,train_loss: 4.200672,test_loss: 4.5867753},
      {x: 8.0,train_loss: 4.354703,test_loss: 4.722338},
      {x: 9.0,train_loss: 3.4552233,test_loss: 3.8237867},
      {x: 10.0,train_loss: 4.0081735,test_loss: 4.37226},
      {x: 11.0,train_loss: 3.0983665,test_loss: 3.4688888},
      {x: 12.0,train_loss: 2.804275,test_loss: 3.196284},
      {x: 13.0,train_loss: 2.5422318,test_loss: 2.9460232},
      {x: 14.0,train_loss: 2.4820704,test_loss: 2.8721325},
      {x: 15.0,train_loss: 2.3037722,test_loss: 2.6775932},
      {x: 16.0,train_loss: 2.319597,test_loss: 2.6820045},
      {x: 17.0,train_loss: 2.287949,test_loss: 2.6667166},
      {x: 18.0,train_loss: 2.3550186,test_loss: 2.723203},
      {x: 19.0,train_loss: 2.192787,test_loss: 2.558163},
      {x: 20.0,train_loss: 1.9423046,test_loss: 2.3107495},
      {x: 21.0,train_loss: 1.71944,test_loss: 2.0777512},
      {x: 22.0,train_loss: 1.682455,test_loss: 2.0293765},
      {x: 23.0,train_loss: 1.6979285,test_loss: 2.0402162},
      {x: 24.0,train_loss: 1.5438114,test_loss: 1.8879902},
      {x: 25.0,train_loss: 1.6338603,test_loss: 1.9615581},
      {x: 26.0,train_loss: 1.7820119,test_loss: 2.1162705},
      {x: 27.0,train_loss: 1.3853155,test_loss: 1.7169348},
      {x: 28.0,train_loss: 1.554546,test_loss: 1.8828211},
      {x: 29.0,train_loss: 1.7334173,test_loss: 2.0539973},
      {x: 30.0,train_loss: 2.4269183,test_loss: 2.7475863},
      {x: 31.0,train_loss: 1.8551756,test_loss: 2.1872606},
      {x: 32.0,train_loss: 1.3289632,test_loss: 1.6587485},
      {x: 33.0,train_loss: 1.8541228,test_loss: 2.1806479},
      {x: 34.0,train_loss: 1.1990974,test_loss: 1.5163965},
      {x: 35.0,train_loss: 1.2046058,test_loss: 1.5289824},
      {x: 36.0,train_loss: 1.1360298,test_loss: 1.4586533},
      {x: 37.0,train_loss: 1.1363204,test_loss: 1.4529179},
      {x: 38.0,train_loss: 1.1680061,test_loss: 1.4830029},
      {x: 39.0,train_loss: 1.0588886,test_loss: 1.385571},
      {x: 40.0,train_loss: 1.1555754,test_loss: 1.4777229},
      {x: 41.0,train_loss: 1.1003013,test_loss: 1.4173299},
      {x: 42.0,train_loss: 1.1143948,test_loss: 1.4393916},
      {x: 43.0,train_loss: 1.1036872,test_loss: 1.4186021},
      {x: 44.0,train_loss: 1.0274608,test_loss: 1.3401544},
      {x: 45.0,train_loss: 1.0641946,test_loss: 1.3718586},
      {x: 46.0,train_loss: 1.0262651,test_loss: 1.3404529},
      {x: 47.0,train_loss: 1.0209534,test_loss: 1.3322779},
      {x: 48.0,train_loss: 0.96381164,test_loss: 1.2711506},
      {x: 49.0,train_loss: 1.1069648,test_loss: 1.4141006},
      {x: 50.0,train_loss: 1.0832171,test_loss: 1.385837},
      {x: 51.0,train_loss: 0.87014437,test_loss: 1.1813643},
      {x: 52.0,train_loss: 0.91364104,test_loss: 1.2212498},
      {x: 53.0,train_loss: 1.1172843,test_loss: 1.4189619},
      {x: 54.0,train_loss: 0.9055687,test_loss: 1.2024182},
      {x: 55.0,train_loss: 0.8837835,test_loss: 1.1862142},
      {x: 56.0,train_loss: 0.8650466,test_loss: 1.1691939},
      {x: 57.0,train_loss: 0.8587917,test_loss: 1.1638277},
      {x: 58.0,train_loss: 0.82550555,test_loss: 1.1195616},
      {x: 59.0,train_loss: 0.7670726,test_loss: 1.0606058},
      {x: 60.0,train_loss: 0.7978475,test_loss: 1.0935639},
      {x: 61.0,train_loss: 0.87506384,test_loss: 1.1776811},
      {x: 62.0,train_loss: 0.76556903,test_loss: 1.0609546},
      {x: 63.0,train_loss: 0.84733737,test_loss: 1.1358362},
      {x: 64.0,train_loss: 0.76044744,test_loss: 1.0637101},
      {x: 65.0,train_loss: 0.8581365,test_loss: 1.1651871},
      {x: 66.0,train_loss: 0.751442,test_loss: 1.0552881},
      {x: 67.0,train_loss: 0.7376139,test_loss: 1.0395453},
      {x: 68.0,train_loss: 0.76216835,test_loss: 1.066148},
      {x: 69.0,train_loss: 0.816317,test_loss: 1.1178013},
      {x: 70.0,train_loss: 0.8039098,test_loss: 1.107752},
      {x: 71.0,train_loss: 0.6942105,test_loss: 0.99170005},
      {x: 72.0,train_loss: 0.6777372,test_loss: 0.9855148},
      {x: 73.0,train_loss: 0.6975908,test_loss: 0.9937415},
      {x: 74.0,train_loss: 0.8483986,test_loss: 1.1540412},
      {x: 75.0,train_loss: 0.7582445,test_loss: 1.0623702},
      {x: 76.0,train_loss: 0.6678553,test_loss: 0.9762748},
      {x: 77.0,train_loss: 0.7449777,test_loss: 1.0505522},
      {x: 78.0,train_loss: 0.64373976,test_loss: 0.95427823},
      {x: 79.0,train_loss: 0.65335375,test_loss: 0.96177316},
      {x: 80.0,train_loss: 0.7406068,test_loss: 1.0410926},
      {x: 81.0,train_loss: 0.6611173,test_loss: 0.9605732},
      {x: 82.0,train_loss: 0.6295463,test_loss: 0.9358709},
      {x: 83.0,train_loss: 0.6626915,test_loss: 0.97012913},
      {x: 84.0,train_loss: 0.61929244,test_loss: 0.92068684},
      {x: 85.0,train_loss: 0.7452199,test_loss: 1.055573},
      {x: 86.0,train_loss: 0.6107387,test_loss: 0.9234993},
      {x: 87.0,train_loss: 0.61902887,test_loss: 0.9299619},
      {x: 88.0,train_loss: 0.72588503,test_loss: 1.0362667},
      {x: 89.0,train_loss: 0.6243207,test_loss: 0.94289345},
      {x: 90.0,train_loss: 0.6176859,test_loss: 0.9239325},
      {x: 91.0,train_loss: 0.63568014,test_loss: 0.94496113},
      {x: 92.0,train_loss: 0.61381704,test_loss: 0.92865527},
      {x: 93.0,train_loss: 0.64529663,test_loss: 0.958962},
      {x: 94.0,train_loss: 0.6222389,test_loss: 0.9305466},
      {x: 95.0,train_loss: 0.61104125,test_loss: 0.91989744},
      {x: 96.0,train_loss: 0.5956532,test_loss: 0.89600986},
      {x: 97.0,train_loss: 0.5904863,test_loss: 0.8961074},
      {x: 98.0,train_loss: 0.7201092,test_loss: 1.0302491},
      {x: 99.0,train_loss: 0.57096875,test_loss: 0.8758858},
      {x: 100.0,train_loss: 0.5853687,test_loss: 0.8945185},
      {x: 101.0,train_loss: 0.59041214,test_loss: 0.89806384},
      {x: 102.0,train_loss: 0.5498703,test_loss: 0.85914284},
      {x: 103.0,train_loss: 0.61756164,test_loss: 0.93483734},
      {x: 104.0,train_loss: 0.5577688,test_loss: 0.8727822},
      {x: 105.0,train_loss: 0.5839414,test_loss: 0.89679307},
      {x: 106.0,train_loss: 0.5199162,test_loss: 0.8304754},
      {x: 107.0,train_loss: 0.53078854,test_loss: 0.8399023},
      {x: 108.0,train_loss: 0.63375425,test_loss: 0.9349385},
      {x: 109.0,train_loss: 0.5225404,test_loss: 0.8337844},
      {x: 110.0,train_loss: 0.59659255,test_loss: 0.89838904},
      {x: 111.0,train_loss: 0.53909516,test_loss: 0.84929425},
      {x: 112.0,train_loss: 0.5941517,test_loss: 0.90779996},
      {x: 113.0,train_loss: 0.5206886,test_loss: 0.82985973},
      {x: 114.0,train_loss: 0.5337417,test_loss: 0.84118515},
      {x: 115.0,train_loss: 0.54263675,test_loss: 0.8579653},
      {x: 116.0,train_loss: 0.5257385,test_loss: 0.82600987},
      {x: 117.0,train_loss: 0.5259255,test_loss: 0.8281541},
      {x: 118.0,train_loss: 0.52820045,test_loss: 0.8362225},
      {x: 119.0,train_loss: 0.5732971,test_loss: 0.8836261},
      {x: 120.0,train_loss: 0.57928777,test_loss: 0.8885511},
      {x: 121.0,train_loss: 0.6916075,test_loss: 1.003751},
      {x: 122.0,train_loss: 0.5810839,test_loss: 0.89496416},
      {x: 123.0,train_loss: 0.48355365,test_loss: 0.79422843},
      {x: 124.0,train_loss: 0.56724715,test_loss: 0.8832452},
      {x: 125.0,train_loss: 0.46244544,test_loss: 0.7668032},
      {x: 126.0,train_loss: 0.46670765,test_loss: 0.77450967},
      {x: 127.0,train_loss: 0.4960932,test_loss: 0.80449295},
      {x: 128.0,train_loss: 0.4924434,test_loss: 0.7989032},
      {x: 129.0,train_loss: 0.6264115,test_loss: 0.93920696},
      {x: 130.0,train_loss: 0.47421724,test_loss: 0.78733605},
      {x: 131.0,train_loss: 0.4777007,test_loss: 0.789304},
      {x: 132.0,train_loss: 1.0498718,test_loss: 1.3516581},
      {x: 133.0,train_loss: 0.45976782,test_loss: 0.77203923},
      {x: 134.0,train_loss: 0.4877256,test_loss: 0.7892368},
      {x: 135.0,train_loss: 0.46346885,test_loss: 0.76541525},
      {x: 136.0,train_loss: 0.5508943,test_loss: 0.861336},
      {x: 137.0,train_loss: 0.45592168,test_loss: 0.76746154},
      {x: 138.0,train_loss: 0.4509267,test_loss: 0.76194674},
      {x: 139.0,train_loss: 0.48701638,test_loss: 0.8016032},
      {x: 140.0,train_loss: 0.4844208,test_loss: 0.7960285},
      {x: 141.0,train_loss: 0.5807604,test_loss: 0.8969322},
      {x: 142.0,train_loss: 0.4399567,test_loss: 0.7437434},
      {x: 143.0,train_loss: 0.4418556,test_loss: 0.74912125},
      {x: 144.0,train_loss: 0.464319,test_loss: 0.775166},
      {x: 145.0,train_loss: 0.43853375,test_loss: 0.7516677},
      {x: 146.0,train_loss: 0.4688937,test_loss: 0.77812266},
      {x: 147.0,train_loss: 0.4765968,test_loss: 0.77776664},
      {x: 148.0,train_loss: 0.44431463,test_loss: 0.7549682},
      {x: 149.0,train_loss: 0.44767234,test_loss: 0.7588162},
      {x: 150.0,train_loss: 0.4962526,test_loss: 0.80957246},
      {x: 151.0,train_loss: 0.44808334,test_loss: 0.7554826},
      {x: 152.0,train_loss: 0.4887851,test_loss: 0.79359543},
      {x: 153.0,train_loss: 0.4252763,test_loss: 0.73311406},
      {x: 154.0,train_loss: 0.4708097,test_loss: 0.7764003},
      {x: 155.0,train_loss: 0.62595254,test_loss: 0.93445086},
      {x: 156.0,train_loss: 0.44636202,test_loss: 0.753427},
      {x: 157.0,train_loss: 0.47267368,test_loss: 0.783977},
      {x: 158.0,train_loss: 0.433697,test_loss: 0.74107504},
      {x: 159.0,train_loss: 0.40955907,test_loss: 0.72015077},
      {x: 160.0,train_loss: 0.45944345,test_loss: 0.769408},
      {x: 161.0,train_loss: 0.40546536,test_loss: 0.7138861},
      {x: 162.0,train_loss: 0.45649254,test_loss: 0.768299},
      {x: 163.0,train_loss: 0.5459393,test_loss: 0.8579769},
      {x: 164.0,train_loss: 0.6617234,test_loss: 0.9678425},
      {x: 165.0,train_loss: 0.41547456,test_loss: 0.72086674},
      {x: 166.0,train_loss: 0.42719904,test_loss: 0.7341507},
      {x: 167.0,train_loss: 0.41245326,test_loss: 0.7237474},
      {x: 168.0,train_loss: 0.3973284,test_loss: 0.70845306},
      {x: 169.0,train_loss: 0.42077342,test_loss: 0.7301243},
      {x: 170.0,train_loss: 0.4050712,test_loss: 0.71857387},
      {x: 171.0,train_loss: 0.41156825,test_loss: 0.7247989},
      {x: 172.0,train_loss: 0.52231973,test_loss: 0.8332451},
      {x: 173.0,train_loss: 0.4436948,test_loss: 0.75642765},
      {x: 174.0,train_loss: 0.43935135,test_loss: 0.7447463},
      {x: 175.0,train_loss: 0.395793,test_loss: 0.7105296},
      {x: 176.0,train_loss: 0.3988084,test_loss: 0.7117693},
      {x: 177.0,train_loss: 0.38297716,test_loss: 0.6961206},
      {x: 178.0,train_loss: 0.37999305,test_loss: 0.69037986},
      {x: 179.0,train_loss: 0.40795162,test_loss: 0.71440583},
      {x: 180.0,train_loss: 0.47639754,test_loss: 0.7821172},
      {x: 181.0,train_loss: 0.4280995,test_loss: 0.7417125},
      {x: 182.0,train_loss: 0.42825544,test_loss: 0.7438244},
      {x: 183.0,train_loss: 0.42408037,test_loss: 0.7326254},
      {x: 184.0,train_loss: 0.37202317,test_loss: 0.68691176},
      {x: 185.0,train_loss: 0.38545856,test_loss: 0.695733},
      {x: 186.0,train_loss: 0.43736434,test_loss: 0.7515988},
      {x: 187.0,train_loss: 0.46156815,test_loss: 0.76992226},
      {x: 188.0,train_loss: 0.37009737,test_loss: 0.6802773},
      {x: 189.0,train_loss: 0.3828518,test_loss: 0.6959109},
      {x: 190.0,train_loss: 0.42364463,test_loss: 0.73960435},
      {x: 191.0,train_loss: 0.40615314,test_loss: 0.71873593},
      {x: 192.0,train_loss: 0.46211246,test_loss: 0.7739197},
      {x: 193.0,train_loss: 0.37001675,test_loss: 0.6862561},
      {x: 194.0,train_loss: 0.44844082,test_loss: 0.7553297},
      {x: 195.0,train_loss: 0.37823343,test_loss: 0.69238794},
      {x: 196.0,train_loss: 0.47853938,test_loss: 0.79956883},
      {x: 197.0,train_loss: 0.37509984,test_loss: 0.6852211},
      {x: 198.0,train_loss: 0.38966578,test_loss: 0.7038518},
      {x: 199.0,train_loss: 0.37274137,test_loss: 0.6879416},
      {x: 200.0,train_loss: 0.41520146,test_loss: 0.72718036},
      {x: 201.0,train_loss: 0.37875772,test_loss: 0.68866205},
      {x: 202.0,train_loss: 0.3588401,test_loss: 0.67383945},
      {x: 203.0,train_loss: 0.35496208,test_loss: 0.6729287},
      {x: 204.0,train_loss: 0.3907915,test_loss: 0.70112365},
      {x: 205.0,train_loss: 0.3489377,test_loss: 0.66183954},
      {x: 206.0,train_loss: 0.3729661,test_loss: 0.6890206},
      {x: 207.0,train_loss: 0.36865935,test_loss: 0.6803981},
      {x: 208.0,train_loss: 0.3629033,test_loss: 0.6813447},
      {x: 209.0,train_loss: 0.35876453,test_loss: 0.67500275},
      {x: 210.0,train_loss: 0.40227473,test_loss: 0.7124269},
      {x: 211.0,train_loss: 0.371928,test_loss: 0.6872903},
      {x: 212.0,train_loss: 0.48641017,test_loss: 0.8021431},
      {x: 213.0,train_loss: 0.34848374,test_loss: 0.6649299},
      {x: 214.0,train_loss: 0.33783594,test_loss: 0.6545738},
      {x: 215.0,train_loss: 0.4929468,test_loss: 0.80724347},
      {x: 216.0,train_loss: 0.36078236,test_loss: 0.6758928},
      {x: 217.0,train_loss: 0.40911728,test_loss: 0.72716635},
      {x: 218.0,train_loss: 0.3737675,test_loss: 0.685614},
      {x: 219.0,train_loss: 0.55530465,test_loss: 0.8797703},
      {x: 220.0,train_loss: 0.341102,test_loss: 0.6563018},
      {x: 221.0,train_loss: 0.38563806,test_loss: 0.6989165},
      {x: 222.0,train_loss: 0.33865568,test_loss: 0.65426373},
      {x: 223.0,train_loss: 0.41478997,test_loss: 0.73330176},
      {x: 224.0,train_loss: 0.33176634,test_loss: 0.6507034},
      {x: 225.0,train_loss: 0.3212265,test_loss: 0.6373756},
      {x: 226.0,train_loss: 0.3410052,test_loss: 0.65409076},
      {x: 227.0,train_loss: 0.3659621,test_loss: 0.6867713},
      {x: 228.0,train_loss: 0.32709798,test_loss: 0.6442894},
      {x: 229.0,train_loss: 0.61959743,test_loss: 0.93064934},
      {x: 230.0,train_loss: 0.43798602,test_loss: 0.75544226},
      {x: 231.0,train_loss: 0.36830452,test_loss: 0.689329},
      {x: 232.0,train_loss: 0.33760652,test_loss: 0.65516007},
      {x: 233.0,train_loss: 0.37826625,test_loss: 0.6992705},
      {x: 234.0,train_loss: 0.35173684,test_loss: 0.6752448},
      {x: 235.0,train_loss: 0.33869922,test_loss: 0.6630759},
      {x: 236.0,train_loss: 0.49088407,test_loss: 0.81305456},
      {x: 237.0,train_loss: 0.4114651,test_loss: 0.73099923},
      {x: 238.0,train_loss: 0.34452274,test_loss: 0.67067224},
      {x: 239.0,train_loss: 0.3244465,test_loss: 0.6482177},
      {x: 240.0,train_loss: 0.3926521,test_loss: 0.72128975},
      {x: 241.0,train_loss: 0.46752086,test_loss: 0.79236466},
      {x: 242.0,train_loss: 0.3238899,test_loss: 0.64216566},
      {x: 243.0,train_loss: 0.4562956,test_loss: 0.7815231},
      {x: 244.0,train_loss: 0.32652465,test_loss: 0.64581454},
      {x: 245.0,train_loss: 0.3388367,test_loss: 0.66092104},
      {x: 246.0,train_loss: 0.40007803,test_loss: 0.7281394},
      {x: 247.0,train_loss: 0.34574702,test_loss: 0.6732799},
      {x: 248.0,train_loss: 0.3247334,test_loss: 0.64467525},
      {x: 249.0,train_loss: 0.5868007,test_loss: 0.91839486},
      {x: 250.0,train_loss: 0.42874202,test_loss: 0.7477426},
      {x: 251.0,train_loss: 0.37052745,test_loss: 0.6940125},
      {x: 252.0,train_loss: 0.34159678,test_loss: 0.6696289},
      {x: 253.0,train_loss: 0.42077437,test_loss: 0.7506878},
      {x: 254.0,train_loss: 0.32025352,test_loss: 0.64683735},
      {x: 255.0,train_loss: 0.33392385,test_loss: 0.6624497},
      {x: 256.0,train_loss: 0.3219988,test_loss: 0.6458864},
      {x: 257.0,train_loss: 0.32013074,test_loss: 0.64688325},
      {x: 258.0,train_loss: 0.31379387,test_loss: 0.63905895},
      {x: 259.0,train_loss: 0.3433043,test_loss: 0.67245525},
      {x: 260.0,train_loss: 0.59547156,test_loss: 0.91661006},
      {x: 261.0,train_loss: 0.6326287,test_loss: 0.9625415},
      {x: 262.0,train_loss: 0.3529473,test_loss: 0.68212783},
      {x: 263.0,train_loss: 0.32184035,test_loss: 0.64918065},
      {x: 264.0,train_loss: 0.3239714,test_loss: 0.6472855},
      {x: 265.0,train_loss: 0.30743057,test_loss: 0.63366705},
      {x: 266.0,train_loss: 0.29904962,test_loss: 0.62552387},
      {x: 267.0,train_loss: 0.33225414,test_loss: 0.65718675},
      {x: 268.0,train_loss: 0.37190333,test_loss: 0.6988386},
      {x: 269.0,train_loss: 0.31015956,test_loss: 0.63997257},
      {x: 270.0,train_loss: 0.437586,test_loss: 0.76454264},
      {x: 271.0,train_loss: 0.4137297,test_loss: 0.74193376},
      {x: 272.0,train_loss: 0.35797626,test_loss: 0.6838943},
      {x: 273.0,train_loss: 0.382184,test_loss: 0.71066177},
      {x: 274.0,train_loss: 0.4126139,test_loss: 0.7375661},
      {x: 275.0,train_loss: 0.31056347,test_loss: 0.6407039},
      {x: 276.0,train_loss: 0.3058076,test_loss: 0.6333304},
      {x: 277.0,train_loss: 0.3482088,test_loss: 0.679847},
      {x: 278.0,train_loss: 0.30384576,test_loss: 0.6332702},
      {x: 279.0,train_loss: 0.6404071,test_loss: 0.9838237},
      {x: 280.0,train_loss: 0.30731466,test_loss: 0.6386706},
      {x: 281.0,train_loss: 0.30238897,test_loss: 0.6321385},
      {x: 282.0,train_loss: 0.2951772,test_loss: 0.6267235},
      {x: 283.0,train_loss: 0.29431903,test_loss: 0.6275642},
      {x: 284.0,train_loss: 0.2962824,test_loss: 0.62508976},
      {x: 285.0,train_loss: 0.30242553,test_loss: 0.6337674},
      {x: 286.0,train_loss: 0.32467481,test_loss: 0.6599006},
      {x: 287.0,train_loss: 0.449527,test_loss: 0.7761594},
      {x: 288.0,train_loss: 0.43419224,test_loss: 0.76766807},
      {x: 289.0,train_loss: 0.317979,test_loss: 0.648951},
      {x: 290.0,train_loss: 0.49208382,test_loss: 0.8319335},
      {x: 291.0,train_loss: 0.30700493,test_loss: 0.6351846},
      {x: 292.0,train_loss: 0.3498283,test_loss: 0.6814706},
      {x: 293.0,train_loss: 0.28529784,test_loss: 0.6156679},
      {x: 294.0,train_loss: 0.30083847,test_loss: 0.62841386},
      {x: 295.0,train_loss: 0.3107103,test_loss: 0.63873386},
      {x: 296.0,train_loss: 0.30338743,test_loss: 0.6414609},
      {x: 297.0,train_loss: 0.376548,test_loss: 0.7125761},
      {x: 298.0,train_loss: 0.32419762,test_loss: 0.6552894},
      {x: 299.0,train_loss: 0.327925,test_loss: 0.6603518},
      {x: 300.0,train_loss: 0.30331945,test_loss: 0.6360471},
    ],

    margin: {
      top: 20,
      right: 25,
      left: 20,
      bottom: 20
    },
    legend: {
      position: {top: 0, right: 0}
    }
  }
  const pappeSolutionTimePlot = {
    xLabel: {
      name: sprpmedContent[language]['xLabelExactSolutionTimePlot'],
      offset: -8
    },
    yLabel: {
      name: sprpmedContent[language]['yLabelExactSolutionTimePlot'],
      offset: 0
    },
    xUnit: '',
    yUnit: '',
    lines: [
      {
        name:'solution_time',
        color: '#0E99F0',
      }
    ],
    data: [
      {x: 2, solution_time: 1.329},
      {x: 4, solution_time: 1.332},
      {x: 6, solution_time: 1.327},
      {x: 8, solution_time: 1.309},
      {x: 10, solution_time: 1.315},
      {x: 12, solution_time: 1.315},
      {x: 14, solution_time: 1.311},
      {x: 16, solution_time: 1.335},
      {x: 18, solution_time: 1.333},
      {x: 20, solution_time: 1.332},
      {x: 22, solution_time: 1.323},
      {x: 24, solution_time: 1.311},
      {x: 26, solution_time: 1.305},
      {x: 28, solution_time: 1.324},
      {x: 30, solution_time: 1.319},
      {x: 32, solution_time: 1.312},
      {x: 34, solution_time: 1.305},
      {x: 36, solution_time: 1.331},
      {x: 38, solution_time: 1.293},
      {x: 40, solution_time: 1.327},
      {x: 42, solution_time: 1.305},
      {x: 44, solution_time: 1.310},
      {x: 46, solution_time: 1.307},
      {x: 48, solution_time: 1.305},
      {x: 50, solution_time: 1.307},
      {x: 52, solution_time: 1.307},
      {x: 54, solution_time: 1.296},
      {x: 56, solution_time: 1.300},
      {x: 58, solution_time: 1.297},
      {x: 60, solution_time: 1.336},
      {x: 62, solution_time: 1.297},
      {x: 64, solution_time: 1.310},
      {x: 66, solution_time: 1.322},
      {x: 68, solution_time: 1.316},
      {x: 70, solution_time: 1.335},
    ],
    margin: {
      top: 20,
      right: 25,
      left: 20,
      bottom: 20
    },
    legend: {}
  }
  const mseHeatmap = {
    xLabel: {
      values: [6,8,10,12,14],
      name: sprpmedContent[language]['xLabelMseHeatmap'],
    },
    yLabel: {
      values: [1250,1000,750,500,250],
      width: '50px',
      name: sprpmedContent[language]['yLabelMseHeatmap']
    },
    zLabel: {
      name: sprpmedContent[language]['zLabelMseHeatmap']
    },
    grid: [[0.93,0.79,0.69,0.68,0.73],[0.92,0.80,0.72,0.64,0.68],[1.04,0.67,0.66,0.65,0.66],[1.24,0.70,0.61,0.60,0.72],[1.68,1.17,0.86,0.99,0.80]],
  }
  const solutionTimeHeatmap = {
    xLabel: {
      values: [6,8,10,12,14],
      name: sprpmedContent[language]['xLabelSolutionTimeHeatmap'],
    },
    yLabel: {
      values: [1250,1000,750,500,250],
      width: '50px',
      name: sprpmedContent[language]['yLabelSolutionTimeHeatmap']
    },
    zLabel: {
      name: sprpmedContent[language]['zLabelSolutionTimeHeatmap']
    },
    grid: [[1.705,1.977,2.448,2.681,2.976],[1.241,1.466,1.746,1.987,2.276],[0.891,1.042,1.26,1.429,1.672],[0.58,0.716,0.816,0.967,1.061],[0.254,0.284,0.315,0.348,0.35]],
  }
  
  


  const instanceFile = 'no_aisles: 10\nno_cells_aisle: 45\naisle_width: 2\ncell_width: 1.5\ncell_length: 1\nno_articles: 40\n\npickingpositions: \n0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 0 0 \n1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 1 0 0 0 1 0 0 0 0 0 0 0 \n0 0 0 1 0 0 0 0 0 0 0 1 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0 0 \n0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0 0 0 0 0 0 0 0 0 0 1 0 0 \n0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 \n0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 1 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 \n0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 1 0 0 1 0 1 0 0 0 0 0 \n0 0 0 1 1 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 \n1 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 \n0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 1 0 0 0 0 0 0 1 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 1 0 \n\nenddepotpositions_top: \n1 1 0 1 1 0 0 0 1 1 \nenddepotpositions_bottom: \n0 0 1 1 1 1 1 1 1 1 \n\nseed 1000001\n\noptimal tour lenght: 455.0'

  const cellSize = Math.min((width-180)/5,100)

  const quoteNameStyle = {
    color: theme.text['3'],
    fontSize: size.font_size['s'],
    marginTop: size.spacing['xs']
  }

  return(
  <>
    <ContentNav items={sprpmedContent[language]['contentNavItems']} theme={theme} width={width}/>
    <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
      <div id="scroll-container" style={{margin: size.spacing['s']}}>
        <section id={sprpmedContent[language]['contentNavItems'][0]}>
          <BlogHeading title={sprpmedContent[language]['title']} info={sprpmedContent[language]['infoPills']}  theme={theme}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['intro']}</Paragraph>\
          <WarehouseMed theme={theme} service={sprpService} language={language} addAlert={addAlert}/>
          <Paragraph theme={theme} >
            {sprpmedContent[language]['MIP']}
          </Paragraph>
          <Link theme={theme} type={'external'} link={'https://doi.org/10.1287/ijoc.2020.1040'}>Paper</Link>
        </section>
        <section id={sprpmedContent[language]['contentNavItems'][1]}>
          <SectionHeading title={sprpmedContent[language]['contentNavItems'][1]} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{sprpmedContent[language]['Motivation_1']}</Paragraph>
          <ExpandableCard title={'OrderBatching'} theme={theme}>
            <div>{sprpmedContent[language]['OrderBatching']}</div>
            <div style={quoteNameStyle}>Wäscher 2002</div>
          </ExpandableCard>
          <Paragraph theme={theme} >{sprpmedContent[language]['Motivation_2']}</Paragraph>
        </section>
        <section id={sprpmedContent[language]['contentNavItems'][2]}>
          <SectionHeading title={sprpmedContent[language]['contentNavItems'][2]} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroDatensatz']}</Paragraph>
          <List content={sprpmedContent[language]['Vereinfachungen']} theme={theme}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroInstanzen']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['publishData']}</Paragraph>

          <ExpandableCard title={sprpmedContent[language]['instance']} theme={theme}>
            <CodeSnippet theme={theme} title={'aisle10cells45article40p05inst1.txt'} codeString={instanceFile} language={'python'}/>
          </ExpandableCard>
          <Paragraph theme={theme} >{sprpmedContent[language]['AufteilenDaten']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroMIPTime']}</Paragraph>
          <Lines width={width} plot={exactSolutionTimePlot}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['MIPTime']}</Paragraph>

          <IconCard type={'info'} theme={theme}>
          {'System Details:'} 
            <List alone={false} theme={theme} content={['Windows 10 Pro','AMD Ryzen 5 5600G Prozessor (3.9 GHz)','32 GB RAM','Julia 1.7.3','Gurobi 9.5.1']}/>
          </IconCard>
        </section>

        {/* <section id={sprpmedContent[language]['contentNavItems'][3]}><Paragraph theme={theme} >{'Intro ANNs'}</Paragraph></section> */}

        <section id={sprpmedContent[language]['contentNavItems'][4-1]}>
          <SectionHeading title={sprpmedContent[language]['contentNavItems'][4-1]} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroPappe']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroParameterPappe']}</Paragraph>
          <List content={sprpmedContent[language]['PappeParameter']} theme={theme}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['PappeTraining']}</Paragraph>
          <Lines width={width} plot={evalLossPappePlot}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['PappeMSE']}</Paragraph>
          <ExpandableCard title={'Overfitting'} theme={theme}>
            <div>{sprpmedContent[language]['Overfitting']}</div>
            <div style={quoteNameStyle}>Webb 2011</div>
          </ExpandableCard>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroANNTime']}</Paragraph>
          <Lines width={width} plot={pappeSolutionTimePlot}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['PappeTime_1']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['PappeTime_2']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['PappeSummary']}</Paragraph>
          <IconCard type={'info'} theme={theme}>
            {`Pappe  ${languageDict[language]['metrics']} (testset_1): `}
            <List alone={false} theme={theme} content={sprpmedContent[language]['pappeMetrics']}/>
          </IconCard>
        </section>
        <section id={sprpmedContent[language]['contentNavItems'][5-1]}>
          <SectionHeading title={sprpmedContent[language]['contentNavItems'][5-1]} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroHyper']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroRastersuche_1']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['IntroRastersuche_2']}</Paragraph>
          <List content={sprpmedContent[language]['Rastersuche']} theme={theme}/>
          <Paragraph theme={theme} >{sprpmedContent[language]['NeuronenAnzahl']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['ANNnaming']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['HyperTraining']}</Paragraph>

          <div style={{margin: size.spacingTopBottom['l']}}>
            <Heatmap plot={mseHeatmap} cellSize={cellSize} theme={theme}/>
          </div>
          <Paragraph theme={theme} >{sprpmedContent[language]['HyperMSE']}</Paragraph>
          <div style={{margin: size.spacingTopBottom['l']}}>
            <Heatmap plot={solutionTimeHeatmap} cellSize={cellSize} theme={theme}/>
          </div>
          <Paragraph theme={theme} >{sprpmedContent[language]['HyperTime']}</Paragraph>
          <Paragraph theme={theme} >{sprpmedContent[language]['HyperFinal']}</Paragraph>
        </section>
        <section id={sprpmedContent[language]['contentNavItems'][6-1]}>
          <SectionHeading title={sprpmedContent[language]['contentNavItems'][6-1]} theme={theme} font_size={'l'}/>  

          <Paragraph theme={theme} >{sprpmedContent[language]['IntroFinalANNs']}</Paragraph>

          <Paragraph theme={theme} >{sprpmedContent[language]['ANNSummary']}</Paragraph>
          <IconCard type={'info'} theme={theme}>
            {`ANN 10_250  ${languageDict[language]['metrics']} (testset_2): `}
            <List alone={false} theme={theme} content={sprpmedContent[language]['10_250Metrics']}/>
          </IconCard>
          <IconCard type={'info'} theme={theme}>
            {`ANN 10_500  ${languageDict[language]['metrics']} (testset_2): `}
            <List alone={false} theme={theme} content={sprpmedContent[language]['10_500Metrics']}/>
          </IconCard>
          <Paragraph theme={theme} >{sprpmedContent[language]['Conclusion']}</Paragraph>
        </section>
      </div>
      
    </HorizontalCenter>
  </>
  )
  


}




const SPRPmepPage = () => {
  return (
    <PageFrame title={'SPRP'} navType={'title'}>
      <SPRPmep/>
    </PageFrame>
  );
}

export default SPRPmepPage;
