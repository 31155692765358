/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/python/saveImg'

const save = async (lines,height,width) => {
  const payload = { lines,height,width }
  const response = await axios.post(baseUrl, payload);
  return response
}

export default { save }