/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/login'

const login = async credentials => {
  const response = await axios.post(baseUrl, credentials);
  return response.data
}

const verify = async credentials => {
  const response = await axios.post(`${baseUrl}/token`, credentials);
  return response.data
}

export default { login,verify }