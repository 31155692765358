import size from '../../DesignSystem/size.json';

const SectionHeading = ({theme,title,font_size}) => {

  const containerStyle = {
    padding: size.spacingTopBottom['m'],
    paddingTop: size.spacing['l'],
    maxWidth: size.MAX_WIDTH_BLOG,
  }
  const titleStyle = {
    color: theme.text['1'],
    fontSize: size.font_size[font_size],
  }

  return(
    <div style={containerStyle}>
      <h2 style={titleStyle}>{title}</h2>
    </div>
  )
}

export default SectionHeading;