import { motion } from "framer-motion"

const BurgerMenu = ({isOpen,size,theme}) => {
  // const [isOpen, setIsOpen] = useState(false);

  const padding = '0.2'
  const height = '0.085'

  const boxStyle = {
    position: 'relative',
    height: size,
    width: size,
    backgroundColor: 'transparent',
  }

  const upperBarStyle = {
    position: 'absolute',
    top: `${padding*100}%`,
    left: '15%',
    width: '70%',
    height: `${height*100}%`,
    backgroundColor: theme.text['1'],
    borderRadius: '4px',
  };
  const middleBarStyle = {
    position: 'absolute',
    top: `calc(50% - 0.5* ${height*100}%)`,
    left: '15%',
    width: '70%',
    height: `${height*100}%`,
    backgroundColor: theme.text['1'],
    borderRadius: '4px',
  };

  const lowerBarStyle = {
    position: 'absolute',
    top: `calc(100% - ${padding*100}% - ${height*100}%)`,
    left: '15%',
    width: '70%',
    height: `${height*100}%`,
    backgroundColor: theme.text['1'],
    borderRadius: '4px',
  };

  return(
    <motion.div 
      style={boxStyle}
      // onClick={() => setIsOpen(!isOpen)}
    >
      <motion.span 
        style={upperBarStyle}
        animate={{y: isOpen ? ['0%', '300%'] : ['300%','300%', '0%'], rotate: isOpen ? [0,0,-45] : [-45,0,0]}}
      ></motion.span>
      <motion.span 
        style={middleBarStyle}
        animate={{scaleX: isOpen ? [1,0] : [0,0.5,1]}}
      ></motion.span>
      <motion.span 
        style={lowerBarStyle}
        animate={{y: isOpen ? ['0%', '-300%'] : ['-300%','-300%','0%'], rotate: isOpen ? [0,0,45] : [45,0,0]}}
      ></motion.span> 
    </motion.div>
  )
}

export default BurgerMenu