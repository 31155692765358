import { useState } from 'react'
import { motion } from "framer-motion";


const ArrowIcon = ({theme,size}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const arrowStyle = {
    position: 'relative',
    height: size,
    width: size,
    backgroundColor: `${theme.primary}55`,
    borderRadius: size,
    // backgroundColor: 'red',
  }
  const upperBarStyle = {
    position: 'absolute',
    top: '57.5%',
    left: '30%',
    width: '40%',
    height: '10%',
    backgroundColor: theme.primary,
    borderRadius: '7% 25% 25% 7%',
    // transform: 'translateY(-10px)',
  };
  const lowerBarStyle = {
    position: 'absolute',
    top: '32.5%',
    left: '30%',
    width: '40%',
    height: '10%',
    backgroundColor: theme.primary,
    borderRadius: '7% 25% 25% 7%',
  };

  return (
    <div style={arrowStyle} onClick={()=>{handleToggle()}}>
      <motion.span 
        style={upperBarStyle}
        animate={{ rotate: isOpen ? 125 : 55 }}
        transition={{ duration: 0.7, type: "spring" }}
      ></motion.span>

      <motion.span 
        style={lowerBarStyle}
        animate={{ rotate: isOpen ? 235 : 305 }}
        transition={{ duration: 0.7, type: "spring" }}
      ></motion.span>
    </div>
    
  );
};

export default ArrowIcon;