

import size from '../../DesignSystem/size.json';

import Button from '../Button/Button';

const PortfolioCard = ({theme,image,title,supTitle,link,style}) => {

  const cardStyle = {
    width: '320px',
    height: '400px',
    backgroundColor: theme.bg_variation,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: size.spacing['m'],
    borderRadius: '4px',
    border: `1px solid ${theme.hairline}`,
  }
  
  const titleStyle = {
    color: theme.text['1'],
    textAlign: 'center',
    marginBottom: size.spacing['s'],
    marginTop: size.spacing['m'],
  }
  const subTitleStyle = {
    color: theme.text['2'],
    textAlign: 'center',
  }

  return(
    <div style={{...cardStyle,...style}}>
      
      <img style={{maxWidth:'200px'}}src={image} alt={''}></img>
     
      <div>
        <div style={titleStyle}>{title}</div>
        <div style={subTitleStyle}>{supTitle}</div>
      </div>
      <div style={{maxHeight: '40px', overflow: 'hidden'}}>
      <div style={{marginTop: 'auto'}}>
        <Button link={link} emphasis={'high'} theme={theme}> mehr </Button>
      </div>
    </div>
    </div>
  )
}

export default PortfolioCard;