import evaluate_cmap from './Colormap';

import size from '../../DesignSystem/size.json';

const plot = {
  xLabel: {
    values: [6,8,10,12,14],
    name: 'number of hidden layers',
  },
  yLabel: {
    values: [1250,1000,750,500,250],
    width: '50px',
    name: 'number of neurons (n<sub>1</sub>)'
  },
  zLabel: {
    name: 'zLabel'
  },
  grid: [[0.93,0.79,0.69,0.68,0.73],[0.92,0.80,0.72,0.64,0.68],[1.04,0.67,0.66,0.65,0.66],[1.24,0.70,0.61,0.60,0.72],[1.68,1.17,0.86,0.99,0.80]],
}





const Cell = ({value,max,min,cellSize}) => {
 
  const cellStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: cellSize,
    width: cellSize,
    color: 'white',
    backgroundColor: evaluate_cmap((value-min)/(max-min),false,true)
  }

  return(
    <div style={cellStyle} >
      {value}
    </div>
  )
}

const Heatmap = ({plot,cellSize,theme}) => {

  const max =  Math.max(...plot.grid.flat())
  const min =  Math.min(...plot.grid.flat())

  const containerStyle = {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    fontSize: cellSize< 100 ? size.font_size['xs'] : size.font_size['s'],
  }
  
  const rowStyle = {
    display: 'flex',
  }
  const colorLegendStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    alignItems: 'center',
    height: plot.grid.length * cellSize,
    width: '30px',
    background: `linear-gradient(
      to top,
      ${evaluate_cmap(0,false,true)},
      ${evaluate_cmap(0.1,false,true)},
      ${evaluate_cmap(0.2,false,true)},
      ${evaluate_cmap(0.3,false,true)},
      ${evaluate_cmap(0.4,false,true)},
      ${evaluate_cmap(0.5,false,true)},
      ${evaluate_cmap(0.6,false,true)},
      ${evaluate_cmap(0.7,false,true)},
      ${evaluate_cmap(0.8,false,true)},
      ${evaluate_cmap(0.9,false,true)},
      ${evaluate_cmap(1,false,true)}
    )`,
    marginLeft: size.spacing['m'],
  }

  const axisStyle = {
    display: 'flex',
    color: theme.text['2'],
    // border: '1px solid red',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const yLabelStyle = {
    color: theme.text['2'],
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    textAlign: 'center',
    height: cellSize*plot.yLabel.values.length,
  }
  const zLabelStyle = {
    color: theme.text['2'],
    writingMode: 'vertical-rl',
    transform: 'rotate(0deg)',
    textAlign: 'center',
    // height: cellSize*plot.yLabel.values.length,
  }

  return(
    <div style={containerStyle}>
      <div style={yLabelStyle} dangerouslySetInnerHTML= {{__html: plot.yLabel.name }}></div>
      <div>
        { plot.grid.map((row,i) => {
          return(
            <div style={rowStyle} key={i}>
              <span style={{...axisStyle,...{width:plot.yLabel.width}}}>{plot.yLabel.values[i]}</span>
              {row.map((cell,j) => {
                return(
                  <Cell value={cell} max={max} min={min} cellSize={cellSize} key={j}/>
                )
              })}
            </div>
          )
        })}
        <div style={{...axisStyle,...{marginLeft:plot.yLabel.width}}}>
          {plot.xLabel.values.map((label,j) => {
            return(
              <span style={{...axisStyle,...{width: cellSize,}}} key={j} >{label}</span>
            )
          })}
        </div>
        <div dangerouslySetInnerHTML= {{__html: plot.xLabel.name }} style={{...axisStyle,...{width:cellSize*plot.xLabel.values.length,marginLeft:plot.yLabel.width}}}></div>
      </div>
      <div style={colorLegendStyle}>
        <span style={{color:'black'}}>max</span>
        <div style={zLabelStyle} dangerouslySetInnerHTML= {{__html: plot.zLabel.name }}></div>

        <span style={{color:'white'}}>min</span>
      </div>
      {/* <div style={zLabelStyle} dangerouslySetInnerHTML= {{__html: plot.zLabel.name }}></div> */}
    </div>
  )
}



export default Heatmap