import './Slider.css'

import size from '../../DesignSystem/size.json';

const  Slider = ({value,setValue, width,label='',theme,min,max,step,unit=''}) => {
  
  const wrapperSyle = {
    // margin: `${size.spacing['l']} auto`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.text['1'],
  }
  const sliderStyle = {
    width: width,
    backgroundColor: theme.bg
  }

  return (
    <div style={wrapperSyle}>
      {label && <span>{`${label}: ${value} ${unit}`}</span>}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={event => setValue(parseInt(event.target.value))}
        style={sliderStyle}
      />
    </div>
  );
}

export default Slider;