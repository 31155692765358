import { MapPin,Calendar,Home } from 'react-feather';


import size from '../../../DesignSystem/size.json';
import cvContent from '../../../Content/cvContent.json'

import HorizontalCenter from '../../Helper/Center';
import SectionHeading from '../../Headings/SectionHeading';


const CvElement = ({title,subTitle,company,place,date,theme,width}) => {

  const titleStyle = {
    color: theme.text['1'],
    fontSize: size.font_size['m'],
    // marginTop: size.spacing['s'],
    // marginLeft: size.spacing['m']
  }
  const subTitleStyle = {
    color: theme.text['3'],
    fontSize: size.font_size['xs'],
  }
  const colStyle = {
    display: "flex",
    flexDirection: width>1000 ? 'row' :  "column",
    gap: width>1000 ? '50px' :  "0px",
    justifyContent: "space-between",
    color: theme.text['2'],
    marginBottom: size.spacing['m'],
    padding: width>1000 ? 0 :  size.spacingTopBottom['s'], 
  };
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: size.font_size['s'],
    // padding: size.spacingTopBottom['xs'],
    // marginLeft: width>1000 ?  size.spacing['m'] : size.spacing['l'], 
  }

  return(
    <div>
      <div style={titleStyle} dangerouslySetInnerHTML={{ __html: title }}></div>
      {subTitle && <div style={subTitleStyle} dangerouslySetInnerHTML={{ __html: subTitle }}></div>}
      <div style={colStyle}>
        <div style={iconStyle}><Home size={size.iconSize['s']}/><span style={{width:'150px'}}>{company}</span></div>
        <div style={iconStyle}><MapPin size={size.iconSize['s']}/><span style={{width:'150px'}}>{place}</span></div>
        <div style={iconStyle}><Calendar size={size.iconSize['s']}/><span style={{width:'150px'}}>{date}</span></div>
      </div>
    </div>
  )
}


const Lebenslauf = ({theme,width,language}) => {
  

  const cvStyle = {
    margin: size.spacing['l'],
    // marginTop: size.spacing['l'],
  }
  const headerStyle = {
    color: theme.text['1'],
    fontSize: size.font_size['l'],
    
  }
  const partStyle = {
    marginLeft:  width>1000 ? size.spacing['l'] :  size.spacing['m'],
  }

  return( 
    <HorizontalCenter maxWidth={size.MAX_WIDTH}> 
    
    <div style={cvStyle} >
      <SectionHeading title={cvContent[language]['header']['header']} theme={theme} font_size={'xl'}/>
      <div style={headerStyle}>{cvContent[language]['header']['jobs']}</div>
      <div style={partStyle}>
      {cvContent[language]['jobs'].map((item, i) => {
        return(<CvElement title={item.title} subTitle={item.subTitle} company={item.company} place={item.place} date={item.date} theme={theme} width={width} key={i}/>)
       })
      }
      </div>
      <div style={{...headerStyle,...{marginTop: size.spacing['m']}}}>{cvContent[language]['header']['education']}</div>
      <div style={partStyle}>
      {cvContent[language]['education'].map((item, i) => {
        return(<CvElement title={item.title} subTitle={item.subTitle} company={item.company} place={item.place} date={item.date} theme={theme} width={width} key={i}/>)
       })
      }
      </div>
      
    </div>
    </HorizontalCenter>
  )
}

export default Lebenslauf;