import { useState } from 'react'
import size from '../../DesignSystem/size.json';

const IconBtn = ({ children, theme, onClick, hoverColor }) => {
  const [isHovered, setHovered] = useState(false);

  const btnStyle = {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'center',
    margin: size.spacingLeftRight['s'],
    backgroundColor: theme.mask['2'],
    borderRadius: '4px',
    color: 'white',
    boxShadow: isHovered ? `inset 0 0 8px ${hoverColor}` : 'none',
    border: isHovered ? `2px solid ${hoverColor}` : 'transparent',
    outline: 'none',
    cursor: 'pointer',
  };

  return (
      <button
        style={btnStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        onClick={onClick}
      >
        {children}
      </button>
  );
};


export default IconBtn