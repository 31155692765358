import { useState } from 'react';
import { User, Lock} from 'react-feather'; 

import loginService from '../../services/login'

import Modal from "./Modal"
import Button from "../Button/Button";
import Input from '../Input/Input';
import Notification from '../Notification/Notification';

import size from '../../DesignSystem/size.json';
import languageDict from '../../Content/languageDict.json'



const LoginModal = ({ theme, loginModalIsOpen, toggleLoginModalOpen, setUser, width, addAlert, language }) => {
  const [username, setUsername] = useState('') 
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [statusMessage, setStatusMessage] = useState(null)

  let mobileView = false
  if(width < 1200){
    mobileView = true
  }


  const handleSubmitBtn =  async () => {
  
    let user = ''
    try {
      user = await loginService.login({
        username, password,
      })
      window.localStorage.setItem(
        'loggedUser', JSON.stringify(user)
      ) 
      setUser(user)
      setUsername('')
      setPassword('')
      addAlert({type:'success',message:'Login successful'})
      setErrorMessage(null)
      setStatusMessage(languageDict[language]['rightPW'])
      setTimeout(() => {
        setStatusMessage(null)
      }, 10000)
      

    } catch (exception) {
      setStatusMessage(null)
      setErrorMessage(languageDict[language]['wrongPW'])
      setTimeout(() => {
        setErrorMessage(null)
      }, 5000)
      }
    }

  const handleCancelBtn = () => {
    toggleLoginModalOpen()
  }
  

  const loginModalStyle = {
    maxHeight: '500px',
    minWidth: mobileView ? '280px'  : '600px',
    zIndex: 1001,
    backgroundColor: theme.bg,
    color: theme.text['2'],
    borderRadius: '4px',
    padding: size.spacing['l'],
    border: `1px solid ${theme.hairline}`,
  };
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };
  const titleStyle = {
    alignSelf: 'flex-start',
    color: theme.text['1'], 
    fontSize: size.font_size['l'],
    marginBottom: size.spacing['s']
  };
  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: size.spacing['m'],
    color: theme.text['2'],
    position: 'relative'
  };
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: size.spacing['m'],
  };
  const iconStyle = {
    margin: '6px',
    minWidth: size.iconSize['m']
  };

  return (
    <Modal theme={theme} isOpen={loginModalIsOpen} toggleOpen={toggleLoginModalOpen}>
      <div style={loginModalStyle}>
        <div style={containerStyle}>
          <div style={titleStyle}>Login</div>
          <Notification message={errorMessage} type={"error"} theme={theme}/>
          <Notification message={statusMessage} type={"success"} theme={theme}/>
          <div style={rowStyle}>
            <Input placeholder={'User'} type="text" theme={theme} setContent={setUsername}>
              <User size={size.iconSize['m']} style={iconStyle} />
            </Input>
          </div>
          <div style={rowStyle}>
            <Input placeholder={languageDict[language]['password']}  type={'password'} theme={theme} setContent={setPassword}>
              <Lock size={size.iconSize['m']} style={iconStyle} />
            </Input>
          </div>
          <div style={buttonContainerStyle}>
            <Button emphasis={'medium'} theme={theme} onClick={handleCancelBtn}>
              {languageDict[language]['close']}
            </Button>
            <Button emphasis={'high'} theme={theme} onClick={handleSubmitBtn}>
              {languageDict[language]['login']}
            </Button>
          </div>
          
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal