import { useState} from "react"; //useEffect

import Spinner from "../Animations/Spinner/Spinner";
import Button from "../Button/Button";

import size from '../../DesignSystem/size.json';
import languageDict from '../../Content/languageDict.json'

// import sprpService from '.../services/sprp'

const PickingPosition = ({pos,handleItemChange,items,solution,theme}) => {
  const cellSize = 20

  const buttonStyleLeft = {
    height : `${1.5*cellSize}px`,
    width : `${cellSize}px`,
    border: "2px solid black", 
    backgroundColor: items[pos[0]][pos[1]][0] ? theme['primary'] :"white"
  }
  const buttonStyleRight = {
    height : `${1.5*cellSize}px`,
    width : `${cellSize}px`,
    border: "2px solid black", 
    backgroundColor: items[pos[0]][pos[1]][1] ? theme['primary'] :"white"
  }
  
  const pathStyle = {
    height: `${1.5*cellSize}px`,
    width : `${0.75*cellSize}px`,
    borderLeft: solution["aisles"][pos[0]][pos[1]][0] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    borderRight: solution["aisles"][pos[0]][pos[1]][1] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    borderTop: solution["aisles_partialy_bottom"][pos[0]][pos[1]] ? `3px solid ${theme.text['1']}` : "0px solid rgba(255, 0, 0, 0)" ,
    borderBottom: solution["aisles_partialy_top"][pos[0]][pos[1]] ? `3px solid ${theme.text['1']}` : "0px solid rgba(255, 0, 0, 0)" ,
    margin: '0px 2px'
  }

  const rowStyle = {
    display: 'flex',
    margin:'0px',
    padding:'0px',
  }
  
  const handleCellBtn = (event) => {
    const pos = event.target.id.split("_")

    var old_value = items[pos[0]][pos[1]] 
    var new_value
    if(pos[2] === "left"){
      new_value = [!old_value[0],old_value[1]]
    } else {
      new_value = [old_value[0],!old_value[1]]
    }
    handleItemChange(pos[0],pos[1],new_value)
  }

  return(
    <div style={rowStyle}>
      <button style={buttonStyleLeft} id={`${pos[0]}_${pos[1]}_left`} onClick={handleCellBtn}></button>
      <div style={pathStyle}></div>
      <button style={buttonStyleRight} id={`${pos[0]}_${pos[1]}_right`} onClick={handleCellBtn}></button>
    </div>
  )
}



const Aisle = ({col,j,items,solution,handleItemChange,handleDepotChange,theme,depots}) => {

  const cellSize = 20
  const nRows = items[0].length

  let pathStyleTop
  if(j!=4){
    pathStyleTop = {
      height: `${1*cellSize}px`,
      width : `${2.3*cellSize}px`,
      borderTop: solution["cross_aisle_top"][j][0] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
      borderBottom: solution["cross_aisle_top"][j][1] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    }
  }
  let pathStyleBottom
  if(j!=4){
    pathStyleBottom = {
      height: `${1*cellSize}px`,
      width : `${2.3*cellSize}px`,
      borderTop: solution["cross_aisle_bottom"][j][0] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
      borderBottom: solution["cross_aisle_bottom"][j][1] ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    }
  }
  const connectionPathStyleTop = {
    height: `${1*cellSize}px`,
    width : `${0.75*cellSize}px`,
    borderRight: (solution["aisles"][j][0][1] && !!solution["aisles_partialy_bottom"][j][0]=== false) ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    borderLeft: (solution["aisles"][j][0][0] && !!solution["aisles_partialy_bottom"][j][0]=== false) ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
  }
  const connectionPathStyleBottom = {
    height: `${1*cellSize}px`,
    width : `${0.75*cellSize}px`,
    borderRight: (solution["aisles"][j][nRows-1][1] && !!solution["aisles_partialy_top"][j][nRows-1]=== false) ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
    borderLeft: (solution["aisles"][j][nRows-1][0] && !!solution["aisles_partialy_top"][j][nRows-1]=== false) ? `3px solid ${theme.text['1']}` : "3px solid rgba(255, 0, 0, 0)" ,
  }

  const depotStyleTop = {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: depots[j][1] ? theme['primary']: "darkgray",
    border: solution["posEndDepot"][j][1] ? "4px solid red" : "none",
  }
  const depotStyleBottom = {
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: depots[j][0] ? theme['primary']: "darkgray",
    border: solution["posEndDepot"][j][0] ? "4px solid red" : "none",
  }

  // let x_offset
  // if(window.innerWidth> 1800){x_offset = 422}
  // else{x_offset = 0}

  const handleDepotBtn = (event) => {
    const pos = event.target.id.split("_")

    var old_value = depots[pos[0]] 
    var new_value
    if(pos[1] === "bottom"){
      new_value = [!old_value[0],old_value[1]]
    } else {
      new_value = [old_value[0],!old_value[1]]
    }
    handleDepotChange(pos[0],new_value)
  }
  


  return(
    <div>
      <div style={{height:"30px",position:"relative"}}>
        <div style={{position:"absolute", left:"22px", top:"10px",}}>
          <div style={connectionPathStyleTop}></div>
        </div>
        <div style={{position:"absolute", left:"36px", top:"2px"}}>
          <div style={pathStyleTop}></div>
        </div>
        <div style={{position:"absolute", left:"17px", top:"0px"}}>
          {j!==0 ? (
            <button style={depotStyleTop} id={`${j}_top`} onClick={handleDepotBtn}></button>
          ) : (
            <span style={depotStyleTop}></span>
          )}
          
        </div>
      </div>
      
  
      {col.map((item,i) => <PickingPosition theme={theme} items={items} solution={solution} handleItemChange={handleItemChange} pos={[j,i]} key={`${i}_${j}`}/>)}
      
      <div style={{height:"30px",position:"relative", marginTop:"10px"}}>
        <div style={{position:"absolute", left:"22px", top:"-10px"}}>
          <div style={connectionPathStyleBottom}></div>
        </div>
        <div style={{position:"absolute", left:"35px", top:"2px"}}>
          <div style={pathStyleBottom}></div>
        </div>
        <div style={{position:"absolute", left:"17px", top:"0px"}}>
          <button style={depotStyleBottom} id={`${j}_bottom`} onClick={handleDepotBtn} ></button>
        </div>
      </div>
    </div>
  )
}


const WarehouseMed = ({theme,service,language,addAlert}) => {
  const [isLoading ,setIsLoading] = useState(false);
  const [items, setItems] = useState([[[true, false], [false, false], [false, false], [false, true], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [true, false], [false, false], [false, false], [false, false], [false, true], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [true, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]]] );
  const [depots, setDepots] = useState([[false,true], [false,false], [true,false], [false,false], [true,true]])
  const [solution, setSolution] = useState(
    {
      "aisles":[[[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]],
      "cross_aisle_top":[[0, 0], [0, 0], [0, 0], [0, 0]],
      "cross_aisle_bottom":[[0, 0], [0, 0], [0, 0], [0, 0]],
      "aisles_partialy_top": [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      "aisles_partialy_bottom": [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      "posEndDepot" : [[0,0], [0,0], [0,0], [0,0], [0,0]]
    }
  )
  const handleItemChange = (j,i, value) => {
    let new_items = [...items];
    new_items[j][i] = value
    
    setItems(new_items)
  }
  const handleDepotChange = (j,value) => {
    let new_depots = [...depots];
    new_depots[j] = value
    
    setDepots(new_depots)
  }
  const handleSolveBtn = async () => {
    if(!isLoading){
      setIsLoading(true)

      let items_compressed = items.map((col,j) => {
        let col_items = col.reduce(function(a, e, i) {
            if (e[0] === true || e[1] === true) 
                a.push(i);
            return a;
        }, []);
        return col_items
      })

      const top_depots = depots.reduce(function(a, e, i) {
        if (e[1] === true) 
            a.push(i);
        return a;
      }, []);
      const bottom_depots = depots.reduce(function(a, e, i) {
        if (e[0] === true) 
            a.push(i);
        return a;
      }, []);

      try {
        const s = await service.solve(items_compressed,[bottom_depots,top_depots],5,10)
        setSolution(s.data)
        addAlert({type:'success','message':'Problem successfully solved'})

      } catch (e) {
        addAlert({type:'error','message':'Error while solving the problem'})
      }
      setIsLoading(false)
    }
  }
  const handleResetBtn = () => {
    setItems([[[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]], [[false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false], [false, false]]])
    setDepots([[false,true], [false,false], [false,false], [false,false], [false,false]])
    setSolution({
      "aisles":[[[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]],
      "cross_aisle_top":[[0, 0], [0, 0], [0, 0], [0, 0]],
      "cross_aisle_bottom":[[0, 0], [0, 0], [0, 0], [0, 0]],
      "aisles_partialy_top": [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      "aisles_partialy_bottom": [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      "posEndDepot" : [[false,false], [false,false], [false,false], [false,false], [false,false]]
    })
  }

  const warehouseStyle = {
    display: 'flex',
    justifyContent: 'center',
  }
  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '295px',
    margin: `${size.spacing['l']} auto`, 
  };

  return(
    <div>
      <div style={warehouseStyle} >
        {items.map((col,j) => <Aisle depots={depots} theme={theme} col={col} j={j} items={items} solution={solution} handleItemChange={handleItemChange} handleDepotChange={handleDepotChange} key={j}/>)}
      </div>
      <div style={btn_rowStyle}>
            <Button onClick={handleResetBtn} emphasis={'high'} theme={theme} minWidth={size.spacing['xxl']}>{'reset'}</Button>
            <Button onClick={handleSolveBtn} emphasis={'high'} theme={theme} minWidth={size.spacing['xxl']}>{isLoading ? <Spinner size={"20px"}/>:languageDict[language]['solve']}</Button>
          </div>
    </div>
  )
}


export default WarehouseMed