/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/python/sudoku'

const solveBoard = async (board) => {
  const payload = {board: board}
  const response = await axios.post(baseUrl, payload);
  return response
}

const generateBoard = (p) =>{
  const response = axios.get(`${baseUrl}/${p}`)
  return response
}

export default { solveBoard, generateBoard }