import { useState,useEffect } from 'react'

import tspService from '../services/tsp'

import size from '../DesignSystem/size.json';
import languageDict from '../Content/languageDict.json'
import tspContent from '../Content/tspContent.json'


import ContentNav from '../components/ContentNav/ContentNav';
import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import SectionHeading from '../components/Headings/SectionHeading';
import Paragraph from '../components/Text/Paragraph';
import CodeSnippet from '../components/CodeSnippet/CodeSnippet';
import Button from '../components/Button/Button';
import PageFrame from '../components/PageFrame/PageFrame';
import ExpandableCard from '../components/Cards/ExpandableCard';

import PointCanvas from '../components/Canvas/PointCanvas';
import Spinner from '../components/Animations/Spinner/Spinner';

function generateShapes(canvas_size) {
  return [...Array(16)].map((_, i) => ({
    id: i.toString(),
    x: Math.random() * (canvas_size),
    y: Math.random() * (canvas_size),
    isDragging: false,
  }));
}

const TSP = ({theme,user,language,width,addAlert}) => {
  const [points, setPoints] = useState([]);
  const [route, setRoute] = useState([]);
  const [isLoading ,setIsLoading]= useState(false);

  const canvas_size = Math.min(width-48,500)

  //initial points
  useEffect(() => {
    const size = Math.min(window.innerWidth-48,500)
    setPoints(generateShapes(size));
  }, []);

  const handleResetBtn = async () => {
    setRoute([])
    setPoints([])
  }
  const handleSolveBtn = async () => {
    
    try{
      if(!isLoading) {
        setIsLoading(true)
        const s = await tspService.solve(points)
        setRoute(s.data)
        addAlert({type:'success','message':'Problem successfully solved'})
      } 
    } catch (error) {
      addAlert({type:'error','message':'Error while solving the problem'})
    }
    setIsLoading(false)
  }

  const interactiveStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: size.spacingTopBottom['l']
  }
  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: canvas_size,
    margin: `${size.spacing['l']} auto`, 
    marginBottom: '0px',
  };
 

  const code_init = 'from docplex.mp.model import Model\nfrom docplex.util.environment import get_environment\nimport numpy as np\nimport math'
  const code_distances = 'def calc_distances(points):\n  nPoints = len(points)\n  distances = np.zeros([nPoints,nPoints])\n  for i in range(nPoints):\n    for j in range(nPoints):\n      distances[i,j] = math.sqrt(\n        (points[i]["x"] - points[j]["x"])**2 +\n        (points[i]["y"] - points[j]["y"])**2\n      )'
  const code_initModel = 'def build_model(points):\n  #Formulation from Miller, Tucker and Zemlin (1960)\n\n  nPoints = len(points)\n  distances = calc_distances(points)\n  model = Model("tspSolver")'
  const code_vars = '  x = model.binary_var_matrix(\n    keys1=range(nPoints),\n    keys2=range(nPoints),\n    name=lambda mw: "x(%s_%s)" %(mw[0],mw[1])\n  )\n  y = model.continuous_var_list(\n    keys=range(nPoints),\n    name=lambda mw: "y(%s)" %(mw)\n  )\n  model.x = x\n  model.y = y'
  const code_obj = '  total_cost = model.sum(\n    x[i,j] * distances[i,j] for i in range(nPoints) for j in range(nPoints)\n  )\n  model.add_kpi(total_cost, "Total length")\n  model.minimize(total_cost)'
  const code_constr_2 = '  #leave every point once\n  for i in range(nPoints):\n    model.add_constraint(model.sum(x[i,j] for j in range(nPoints) if i!=j) == 1)'
  const code_constr_1 = '  #visit every point once\n  for j in range(nPoints):\n    model.add_constraint(model.sum(x[i,j] for i in range(nPoints) if j!=i) == 1)'
  const code_constr_3 = '  #subtour elimination (y[i] counts the position in the tour)\n  for i in range(1,nPoints):\n    for j in range(1,nPoints):\n      if(i!=j):\n        model.add_constraint(y[i]- y[j] + (nPoints)*x[i,j] <= nPoints-1)\n'
  const code_return = '  return model\n' 
  const code_main = 'if __name__ == "__main__":\n  points =  [\n    { "x": 5, "y":5 , "id": "0" },\n    { "x": 2, "y":3 , "id": "1" },\n    ...\n  ]\n\n  model = build_model(points)\n  s = model.solve()'

  return(
    <>
    <ContentNav items={tspContent[language]['contentNavItems']} theme={theme} width={width}/>
    <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
      <div id="scroll-container" style={{margin: size.spacing['s']}}>
        <section id={tspContent[language]['contentNavItems'][0]}>
          <BlogHeading title={tspContent[language]['title']} info={tspContent[language]['infoPills']}  theme={theme}/>
          <Paragraph theme={theme} >{tspContent[language]['intro']}</Paragraph>
          <Paragraph theme={theme} >{tspContent[language]['interactive']}</Paragraph>
          <div style={interactiveStyle}>
            <PointCanvas theme={theme} canvas_size={canvas_size} points={points} setPoints={setPoints} route={route}/>
            <div style={btn_rowStyle}>
              <Button emphasis={'high'} onClick={handleResetBtn} theme={theme} minWidth={size.spacing['xxl']}>{'reset'}</Button>
              <Button emphasis={'high'} onClick={handleSolveBtn} theme={theme} minWidth={size.spacing['xxl']}>{isLoading ? <Spinner size={"20px"}/>:languageDict[language]['solve']}</Button>
            </div>
          </div>
          <SectionHeading title={'Code'} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{tspContent[language]['introCode']}</Paragraph>
          <Paragraph theme={theme} >{tspContent[language]['init']}</Paragraph>
          <CodeSnippet theme={theme} title={'init'} codeString={code_init} language={'python'}/>
          <Paragraph theme={theme} >{tspContent[language]['distances']}</Paragraph>
          <ExpandableCard title={tspContent[language]['euclideanTitle']} theme={theme}><span>{tspContent[language]['euclideanText']}</span></ExpandableCard>
          <CodeSnippet theme={theme} title={'distances'} codeString={code_distances} language={'python'}/>
          <Paragraph theme={theme} >{tspContent[language]['initModel']}</Paragraph>
          <CodeSnippet theme={theme} title={'init model'} codeString={code_initModel} language={'python'}/>
        </section> 
        <section id={tspContent[language]['contentNavItems'][1]}> 
          <Paragraph theme={theme} >{tspContent[language]['introVars']}</Paragraph>
          <Paragraph theme={theme} >{tspContent[language]['vars_1']}</Paragraph>
          <Paragraph theme={theme} >{tspContent[language]['vars_2']}</Paragraph>
          <CodeSnippet theme={theme} title={'vars'} codeString={code_vars} language={'python'}/>
        </section> 
        <section id={tspContent[language]['contentNavItems'][2]}> 
          <Paragraph theme={theme} >{tspContent[language]['objective']}</Paragraph>
          <CodeSnippet theme={theme} title={'objective'} codeString={code_obj} language={'python'}/>
        </section> 
        <section id={tspContent[language]['contentNavItems'][3]}> 
          <Paragraph theme={theme} >{tspContent[language]['introConstr']}</Paragraph>
          <Paragraph theme={theme} >{tspContent[language]['constr_1']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_1} language={'python'}/>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_2} language={'python'}/>
          <Paragraph theme={theme} >{tspContent[language]['constr_3']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_3} language={'python'}/>
          <Paragraph theme={theme} >{tspContent[language]['return']}</Paragraph>
          <CodeSnippet theme={theme} title={''} codeString={code_return} language={'python'}/>
        </section> 
        <section id={tspContent[language]['contentNavItems'][4]}> 
          <Paragraph theme={theme} >{tspContent[language]['main']}</Paragraph>
          <CodeSnippet theme={theme} title={'main'} codeString={code_main} language={'python'}/>
        </section>
      </div>
      
    </HorizontalCenter>
    </>

    
  )
}

const TSPPage = () => {
  

  return (
    <PageFrame title={'TSP'} navType={'title'}>
       <TSP/>
    </PageFrame>

  );
}

export default TSPPage;
