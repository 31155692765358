import { motion } from "framer-motion";

const ThemeSwitcher = ({themeName}) => {
  
  const style = {
    width: '24px',
    height: '24px',
    overflow: 'hidden',
  }
  
  return (
    <div style={style}>
      <svg viewBox="0 0 24 24">
        <defs>
          <mask id="myMask">
            <rect width="100%" height="100%" fill="white" />
            <motion.circle
              cx="16"
              cy="9"
              r="5"
              fill="black"
              animate={{ opacity: themeName === "light" ? 1 : 0 }}
              transition={{ duration: 0.5 }}
            />
          </mask>
        </defs>
        <motion.circle
          cx="12"
          cy="12"
          r="5"
          fill="currentColor"
          mask="url(#myMask)"
          animate={{ scale: themeName === "light" ? 1.75 : 1 }}
          transition={{ duration: 0.5 }}
        />
        <motion.path
          d="M12 1v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72l1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42m12.72-12.72l1.42-1.42"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          animate={{
            opacity: themeName === "light" ? 0 : 1,
            rotate: themeName === "light" ? 90 : 0,
          }}
          transition={{ duration: 0.5 }}
        />
      </svg>
    </div>
  );
};

export default ThemeSwitcher;