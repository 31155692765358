import { useState,useEffect } from 'react'

import pMedianService from '../services/pMedian'

import size from '../DesignSystem/size.json';
import languageDict from '../Content/languageDict.json'
import pMedianContent from '../Content/pMedianContent.json'


import ContentNav from '../components/ContentNav/ContentNav';
import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import SectionHeading from '../components/Headings/SectionHeading';
import Paragraph from '../components/Text/Paragraph';
import CodeSnippet from '../components/CodeSnippet/CodeSnippet';
import Button from '../components/Button/Button';
import ExpandableCard from '../components/Cards/ExpandableCard';
import PageFrame from '../components/PageFrame/PageFrame';

import PointCanvas from '../components/Canvas/PointCanvas';
import Slider from '../components/Input/Slider';
import Spinner from '../components/Animations/Spinner/Spinner';

function generateShapes(canvas_size) {
  return [...Array(16)].map((_, i) => ({
    id: i.toString(),
    x: Math.random() * (canvas_size),
    y: Math.random() * (canvas_size),
    isDragging: false,
  }));
}

const PMedian = ({theme,user,language,width,addAlert}) => {
  const [points, setPoints] = useState([]);
  const [route, setRoute] = useState([]);
  const [pVal, setPVal] = useState(3);
  const [isLoading ,setIsLoading]= useState(false);

  const canvas_size = Math.min(width-48,500)

  //initial points
  useEffect(() => {
    const size = Math.min(window.innerWidth-48,500)
    setPoints(generateShapes(size));
  }, []);

  const handleResetBtn = async () => {
    setRoute([])
    setPoints([])
  }
  const handleSolveBtn = async () => {
    
    try{
      if(!isLoading) {
        setIsLoading(true)
        const s = await pMedianService.solve(points,pVal)
        setRoute(s.data)
        addAlert({type:'success','message':'Problem successfully solved'})
      } 
    } catch (error) {
      addAlert({type:'error','message':'Error while solving the problem'})
    }
    setIsLoading(false)
  }

  const interactiveStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: canvas_size,
    margin: `${size.spacing['l']} auto`, 
  };
  const sliderStyle ={ 
    display: 'flex',
    justifyContent: 'center',
    margin: size.spacingTopBottom['m'],
  }


  const code_init = 'from docplex.mp.model import Model\nfrom docplex.util.environment import get_environment\nimport numpy as np\nimport math'
  const code_distances = 'def calc_distances(points):\n  nPoints = len(points)\n  distances = np.zeros([nPoints,nPoints])\n  for i in range(nPoints):\n    for j in range(nPoints):\n      distances[i,j] = math.sqrt(\n        (points[i]["x"] - points[j]["x"])**2 +\n        (points[i]["y"] - points[j]["y"])**2\n      )'
  const code_initModel = 'def build_model(points,nClusters):\n  nPoints = len(points)\n  distances = calc_distances(points)\n  model = Model("pMedianSolver")'
  const code_vars = '  x = model.binary_var_matrix(\n    keys1=range(nPoints),\n    keys2=range(nPoints),\n    name=lambda mw: "x(%s_%s)" %(mw[0],mw[1])\n  )\n  y = model.binary_var_list(\n    keys=range(nPoints),\n    name=lambda mw: "y(%s)" %(mw)\n  )\n  model.x = x\n  model.y = y'
  const code_obj = '  total_cost = model.sum(\n    x[i,j] * distances[i,j] for i in range(nPoints) for j in range(nPoints)\n  )\n  model.add_kpi(total_cost, "Total length")\n  model.minimize(total_cost)'
  const code_constr_1 = '  #constraint (connect every location)\n  for i in range(nPoints):\n    model.add_constraint(model.sum(x[i,j] for j in range(nPoints)) == 1)'
  const code_constr_2 = '  #constraint (select p locations as medians)\n  model.add_constraint( sum(y[j] for j in range(nPoints)) == nClusters)  '
  const code_constr_3 = '  #constraint (only connect a location to a median if the median is chosen)\n  for i in range(nPoints):\n    for j in range(nPoints):\n      model.add_constraint(x[i,j] <= y[j])'
  const code_return = '  return model\n' 
  const code_main = 'if __name__ == "__main__":\n  points =  [\n    { "x": 5, "y":5 , "id": "0" },\n    { "x": 2, "y":3 , "id": "1" },\n    ...\n  ]\n  p = 3\n\n  model = build_model(points,p)\n  s = model.solve()'

  return(
    <>
    <ContentNav items={pMedianContent[language]['contentNavItems']} theme={theme} width={width}/>
    <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
      <div id="scroll-container" style={{margin: size.spacing['s']}}>
        <section id={pMedianContent[language]['contentNavItems'][0]}>
          <BlogHeading title={pMedianContent[language]['title']} info={pMedianContent[language]['infoPills']}  theme={theme}/>
          <Paragraph theme={theme} >{pMedianContent[language]['intro']}</Paragraph>
          <Paragraph theme={theme} >{pMedianContent[language]['interactive']}</Paragraph>
          <div style={interactiveStyle}>
            <div style={sliderStyle}>
             <Slider value={pVal} setValue={setPVal} width={canvas_size} label={"p"} min={1} max={4} step={1} theme={theme}/>
            </div>
            <PointCanvas theme={theme} canvas_size={canvas_size} points={points} setPoints={setPoints} route={route}/>
            <div style={btn_rowStyle}>
              <Button emphasis={'high'} onClick={handleResetBtn} theme={theme} minWidth={size.spacing['xxl']}>{'reset'}</Button>
              <Button emphasis={'high'} onClick={handleSolveBtn} theme={theme} minWidth={size.spacing['xxl']}>{isLoading ? <Spinner size={"20px"}/>:languageDict[language]['solve']}</Button>
            </div>
          </div>
          <SectionHeading title={'Code'} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme} >{pMedianContent[language]['introCode']}</Paragraph>
          <Paragraph theme={theme} >{pMedianContent[language]['init']}</Paragraph>
          <CodeSnippet theme={theme} title={'init'} codeString={code_init} language={'python'}/>
          <Paragraph theme={theme} >{pMedianContent[language]['distances']}</Paragraph>
          <ExpandableCard title={pMedianContent[language]['euclideanTitle']} theme={theme}><span>{pMedianContent[language]['euclideanText']}</span></ExpandableCard>
          <CodeSnippet theme={theme} title={'distances'} codeString={code_distances} language={'python'}/>
          <Paragraph theme={theme} >{pMedianContent[language]['initModel']}</Paragraph>
          <CodeSnippet theme={theme} title={'init model'} codeString={code_initModel} language={'python'}/>
        </section>
        <section id={pMedianContent[language]['contentNavItems'][1]}>
          <Paragraph theme={theme} >{pMedianContent[language]['introVars']}</Paragraph>
          <Paragraph theme={theme} >{pMedianContent[language]['vars_1']}</Paragraph>
          <Paragraph theme={theme} >{pMedianContent[language]['vars_2']}</Paragraph>
          <CodeSnippet theme={theme} title={'vars'} codeString={code_vars} language={'python'}/>
        </section>
        <section id={pMedianContent[language]['contentNavItems'][2]}>
          <Paragraph theme={theme} >{pMedianContent[language]['objective']}</Paragraph>
          <CodeSnippet theme={theme} title={'objective'} codeString={code_obj} language={'python'}/>
        </section>
        <section id={pMedianContent[language]['contentNavItems'][3]}>
          <Paragraph theme={theme} >{pMedianContent[language]['introConstr']}</Paragraph>
          <Paragraph theme={theme} >{pMedianContent[language]['constr_1']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_1} language={'python'}/>
          <Paragraph theme={theme} >{pMedianContent[language]['constr_2']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_2} language={'python'}/>
          <Paragraph theme={theme} >{pMedianContent[language]['constr_3']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_3} language={'python'}/>
          <Paragraph theme={theme} >{pMedianContent[language]['return']}</Paragraph>
          <CodeSnippet theme={theme} title={''} codeString={code_return} language={'python'}/>
        </section>
        <section id={pMedianContent[language]['contentNavItems'][4]}>
          <Paragraph theme={theme} >{pMedianContent[language]['main']}</Paragraph>
          <CodeSnippet theme={theme} title={'main'} codeString={code_main} language={'python'}/>

        </section>
      </div>
      
    </HorizontalCenter>
    </>

    
  )
}

const PMedianPage = () => {
  

  return (
    <PageFrame title={'p-Median'} navType={'title'}>
       <PMedian/>
    </PageFrame>

  );
}

export default PMedianPage;
