/* eslint-disable eqeqeq */
import { Stage, Layer, Circle,Line } from "react-konva";


const PointCanvas = ({ theme, canvas_size, points, setPoints, route }) => {
  
  const handleMouseDown = (e) => {
    const pos = e.target.getStage().getPointerPosition();
    const newPoint = {
      id: points.length.toString(),
      x: pos.x,
      y: pos.y,
    };
    setPoints([...points, newPoint]);
  };


  const canvas_style = {
    margin: `0 auto`,
    border: `1px solid ${theme.hairline}`,
    height: canvas_size,
    width: canvas_size,
    overflow: 'hidden',
    backgroundColor: theme.bg_variation,
  };

  
  
  return (
    <div style={canvas_style}>
      <Stage
        className="canvas"
        onMouseDown={handleMouseDown}
        width={canvas_size}
        height={canvas_size}
      >
        <Layer>
          {route.map(con => {
            const from = points.find(s => s.id == con["from"]);
            const to = points.find(s => s.id == con["to"]);
            
            return (
              <Line
                key={con.id}
                points={[from.x, from.y, to.x, to.y]}
                stroke={theme.text['2']}
              />
            );
          })}
          {points.map((circle) => (
            <Circle
              key={circle.id}
              id={circle.id}
              x={circle.x}
              y={circle.y}
              radius={10}
              fill={theme.primary}
              // opacity={0.8}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default PointCanvas;
