/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/python/tsp'

const solve = async (points) => {
  const payload = { points }
  const response = await axios.post(baseUrl, payload);
  return response
}

export default { solve }