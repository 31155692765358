import { useState,useCallback } from "react"; //useEffect
import { motion,AnimatePresence } from "framer-motion"
import { Plus } from 'react-feather';

import size from '../../DesignSystem/size.json';


const ExpandableCard = ({theme,title,children}) => {
  const [expanded,setExpanded] = useState(false);


  const changeExpanded = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const cardStyle = {
    maxWidth : size.MAX_WIDTH_BLOG,
    color: theme.text['1'],
    borderRadius: '4px',
    border: `1px solid ${theme.hairline}`,
  
    backgroundColor: theme.bg_variation,
    margin: size.spacingTopBottom['m'],
    position: 'relative',
    cursor: 'pointer'
    
  }
  const titleStyle = {
    padding: size.spacing["s"],
    paddingLeft: size.spacing['m'],
    fontSize: size.font_size["m"],
    // fontWeight: size.font_weight['m'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
  const statusIconStyle = {
    color: theme.primary,
    height: size.iconSize["l"],
    width: size.iconSize["l"],
    backgroundColor: `${theme.primary}55`,
    borderRadius: size.font_size["m"]
  }
  const bodyStyle = {
    padding: size.spacing["m"],
    fontSize: size.font_size["s"],
    paddingTop: '0px',
    overflow: 'hidden',
    color: theme.text['2'],
  }

  const statusIconVariants = {
    expanded: {
      rotate: 225
    },
    collapsed: {
      rotate: 0
    }
  };

  const glowStyle = {
    position: 'absolute',
    left: '2px',
    width: '2px',
    height: '40px',
    backgroundColor: theme.primary,
    boxShadow: expanded ? `0px 0px 12px 5px ${theme.primary}` : 'none',
  }

  return (
    <div style={{maxWidth : size.MAX_WIDTH_BLOG}}>
    <motion.div
      layout
      style={cardStyle}
      onClick={changeExpanded}
      // transition={{ layout: { duration: 0.2 } }}
    >
      <motion.div layout="position" style={titleStyle}>
        <motion.span
          style={glowStyle}
          initial={{ opacity: 0 }}
          animate={{ opacity: expanded ? 1 : 0 }}
        ></motion.span>
        {title}
        <motion.div
          style={statusIconStyle}
          animate={expanded ? "expanded" : "collapsed"}
          variants={statusIconVariants}
          transition={{ duration: 0.7, type: "spring" }}
        >
          <Plus size={size.iconSize["l"]} />
        </motion.div>
      </motion.div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            style={{ overflow: "hidden" }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div style={bodyStyle}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
    </div>
  );
}
export default ExpandableCard
