import { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { Twitter,Mail,Linkedin } from 'react-feather'; 
import { Tooltip } from 'react-tooltip';


import IconBtn from '../Button/IconButton';

import size from '../../DesignSystem/size.json';
import languageDict from '../../Content/languageDict.json' 
import navigation from '../../Content/navigation.json';
import Swagger from '../Animations/Icons/Swagger';

const Btn = ({children,theme}) => {
  const [isHovered, setHovered] = useState(false);
  
  const btnStyle = {
    backgroundColor: isHovered ? 'white' : 'transparent',
    borderRadius: '4px',
    padding: size.spacingLeftRight['m'],
    height: '40px',
    border: '1px solid white',
    color: isHovered ? 'black' : 'white',
    fontWeight: isHovered ? size.font_weight['xl'] : size.font_weight['m'],
  }

  return(
    <button
        style={btnStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        // onClick={onClick}
      >
        {children}
      </button>
  )
}


const Footer = ({ theme,width,language }) => {

  const iconsDict = {
    "Mail": <Mail size={size.iconSize['m']}/>,
    "Twitter": <Twitter size={size.iconSize['m']}/>,
    "LinkedIn": <Linkedin size={size.iconSize['m']}/>,
    "Swagger": <Swagger size={size.iconSize['m']} color={'white'}/>,
  }

  const wrapperStyle = {
    background: theme.gradient,
    width: '100%',
    left: '0',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: size.spacing['m'],
  }
  const footerStyle = {
    maxWidth: size.MAX_WIDTH_BLOG,
    width: '100%',
    display: 'flex',
    flexDirection: width>1000 ? 'row' :  "column",
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    gap: '20px',
    paddingLeft: size.spacing['xl'],
    paddingRight: size.spacing['xl'],
  }
  const linkRowStyle = {
    display: 'flex',
  }
  
  return (
    
      <footer style={wrapperStyle}>
        <div style={footerStyle}>
          <div style={linkRowStyle}>
          <Link to={'/SWAGGER'} >
          <span id={'SwaggerBtn'}><IconBtn theme={theme} hoverColor={'white'}>{iconsDict['Swagger']}</IconBtn></span>
          </Link>
          <Tooltip
                anchorId={'SwaggerBtn'}
                place="top"
                content={'SWAGGER'}
                variant={theme['tooltip']}
              />
          {navigation['external'].map((link,i) => {
            return(
            <div key={i}>
              <Link to={link.link}>
              <span id={`${link.link}Btn`}><IconBtn theme={theme} hoverColor={'white'}>{iconsDict[link.name]}</IconBtn></span>
              </Link>
              <Tooltip
                anchorId={`${link.link}Btn`}
                place="top"
                content={link.name}
                variant={theme['tooltip']}
              />
            </div>
            )
          })}

            {/* <Link to={'/'}>
              <IconBtn theme={theme} hoverColor={'white'}><Mail size={size.iconSize['m']}/></IconBtn>
            </Link>
            <IconBtn theme={theme} hoverColor={'white'}><Twitter size={size.iconSize['m']}/></IconBtn>
            <IconBtn theme={theme} hoverColor={'white'}><Linkedin size={size.iconSize['m']}/></IconBtn> */}
          </div>
          <Link to={'/imprint'}>
            <Btn>{languageDict[language]['imprint']}</Btn>
          </Link>
        </div>
      </footer>
   ); 
}
export default Footer;