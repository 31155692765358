import { motion } from "framer-motion"

const LogInOut = ({user}) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const size = '24px' //TOKEN


  const boxStyle = {
    position: 'relative',
    height: size,
    width: size,
    backgroundColor: 'transparent',
  }
    
  return(
    <div 
      style={boxStyle}
      // onClick={() => {setIsLoggedIn(!isLoggedIn)}}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <motion.path 
          d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"
          animate= {{opacity: user ? [1, 0] : [0, 1]}}
        ></motion.path>
        <motion.path 
          d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"
          animate= {{opacity: user ? [0, 1] : [1, 0]}}
        ></motion.path>
        <motion.polyline
          points="10 17 15 12 10 7"
          animate= {{x: user ? ['0%', '20%'] : ['20%', '0%']}}
        ></motion.polyline>
        <motion.line
          x1="15" y1="12" x2="3" y2="12"
          animate= {{x: user ? ['0%', '20%'] : ['20%', '0%']}}
        ></motion.line>
        {/* <motion.polyline
          points="10 17 15 12 10 7"
          animate= {{x: isOpen ? ['0%', '20%'] : ['20%', '0%']}}
        ></motion.polyline>
        <motion.line
          x1="15" y1="12" x2="3" y2="12"
          animate= {{x: isOpen ? ['0%', '20%'] : ['20%', '0%']}}
        ></motion.line> */}
      </svg>
    </div>
  )  
}
export default LogInOut;
