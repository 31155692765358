import React, { useEffect } from "react";
import { motion,useAnimationControls } from "framer-motion";
import { useInView } from "react-intersection-observer";

import size from '../../DesignSystem/size.json';

const ProgressLine = ({label,percentage,theme}) => {
  const controls = useAnimationControls();
  const [ref, inView] = useInView();

  const innerBarVariants = {
    collapsed: { width: "0%"},
    expanded: { 
      width: `${percentage}%`,
      transition: { duration: 1.5 }
    }
  };
  // const innerBarVariants = {
  //   collapsed: { },
  //   expanded:  { } //, transition: { duration: 1, ease: "linear" }
  // };
  


  useEffect(() => {
    if (inView) {
      controls.start("expanded");
    }
  }, [controls, inView]);


  const outerBarStyle = {
    height: size.font_size['m'],
    backgroundColor: 'white',
    borderRadius: '4px',
  }
  const innerBarStyle = {
    height: size.font_size['m'],
    backgroundColor : theme.primary,
    borderRadius: '4px',
  }
  
  const labelStyle = {
    color: theme.text['1'],
    height: size.font_size['l'],
  }

  return (
    <div style={{marginBottom: size.spacing['m']}}>
      <label style={labelStyle}>{label}</label>
      <div className="progress" style={outerBarStyle}>
        <motion.div
        ref={ref}
        animate={controls}
        initial="collapsed"
        variants={innerBarVariants}
        style={innerBarStyle}
        ></motion.div>
      </div>
    </div>
  );
};

export default ProgressLine;