import { useState, useEffect } from 'react'


import sudokuService from "../services/sudoku";

import size from '../DesignSystem/size.json';
import languageDict from '../Content/languageDict.json'
import sudokuContent from '../Content/sudokuContent.json'


import ContentNav from '../components/ContentNav/ContentNav';
import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import SectionHeading from '../components/Headings/SectionHeading';
import Paragraph from '../components/Text/Paragraph';
import CodeSnippet from '../components/CodeSnippet/CodeSnippet';
import Button from '../components/Button/Button';
import IconCard from '../components/Cards/IconCard';
import SudokuBoard from '../components/SudokuBoard/SudokuBoard';
import PageFrame from '../components/PageFrame/PageFrame';
import Spinner from '../components/Animations/Spinner/Spinner';
import Slider from '../components/Input/Slider';

const Sudoku = ({theme,user,language,width,addAlert,removeAlert}) => {

  const i = [[6, 0, 0, 2, 0, 7, 3, 0, 1], [7, 0, 4, 3, 0, 0, 0, 5, 6], [0, 0, 0, 8, 5, 0, 2, 4, 7], [8, 0, 7, 9, 0, 2, 5, 0, 0], [3, 5, 6, 0, 7, 8, 9, 0, 2], [2, 9, 0, 5, 6, 0, 0, 7, 8], [0, 1, 2, 6, 3, 9, 0, 0, 5], [0, 0, 8, 1, 0, 0, 6, 3, 9], [9, 6, 0, 7, 8, 0, 0, 0, 4]]
  
  const [board,setBoard] = useState(i);
  const [solvedBoard, setSolvedBoard] = useState(false);
  const [isLoading ,setIsLoading]= useState(false);
  const [p, setP] = useState(40);
  
  useEffect(() => {
    sudokuService.generateBoard(p/100).then( response => {
      setBoard(response.data)
    })
    .catch(error => {
       addAlert({type:'error','message':'Error while catching new board'})
      // setTimeout(() => { removeAlert(newAlert.id) }, 10000); 
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleSolveBtn = async() => {
    try {
      if(!isLoading && !solvedBoard){
        setIsLoading(true)
        const s = await sudokuService.solveBoard(board)
        setSolvedBoard(s.data)
        addAlert({type:'success','message':'Sudoku successfully solved'})
        setIsLoading(false)
        // setTimeout(() => { removeAlert(newAlert.id) }, 5000); 
      }
    } catch (error) {
      addAlert({type:'error','message':'Error while solving'})
      // setTimeout(() => { removeAlert(newAlert.id) }, 10000); 
    }
  }
  const handleNewBoardBtn = async () => {
    try {
      const newBoard = await sudokuService.generateBoard(p/100)
      setBoard(newBoard.data)
      setSolvedBoard(false)
      addAlert({type:'success','message':'New board'})
      // setTimeout(() => { removeAlert(newAlert.id) }, 5000); 
    } catch (error) {
      addAlert({type:'error','message':'Error while catching new board'})
      // setTimeout(() => { removeAlert(newAlert.id) }, 10000); 
    }
  }

 
  const code_init = 'from docplex.mp.model import Model\nfrom docplex.util.environment import get_environment\n\ndef build_model(board):\n  model = Model("SudokuSolver")'
  const code_vars = '  x = model.binary_var_cube(\n    keys1=range(1,10),\n    keys2=range(1,10),\n    keys3=range(1,10),\n    name=lambda mw: "x(%s,%s_%s)" %(mw[0],mw[1],mw[2])\n  )\n  model.x = x'
  const code_constr_1 = '  #constraint (one number per cell) \n  for i in range(1,10):\n    for j in range(1,10):\n      model.add_constraint(model.sum(x[i,j,k] for k in range(1,10)) == 1)'
  const code_constr_2 = '  #constraint (each number once per row)\n  for i in range(1,10):\n    for k in range(1,10):\n      model.add_constraint(model.sum(x[i,j,k] for j in range(1,10)) == 1)'
  const code_constr_3 = '  #constraint (each number once per colum)\n  for j in range(1,10):\n    for k in range(1,10):\n      model.add_constraint(model.sum(x[i,j,k] for i in range(1,10)) == 1)'
  const code_constr_4 = '  #constraint (each number once per 3x3-Field)\n  for p in [1,4,7]:\n    for q in [1,4,7]:\n      for k in range(1,10):\n        model.add_constraint(model.sum(x[i,j,k] for i in (p,p+1,p+2) for j in (q,q+1,q+2)) == 1)'
  const code_constr_5 = '  #constraint (put the given numbers from the Sudoku into the model)\n  for i in range(0,9):\n    for j in range(0,9):\n      if(board[i][j] != 0):\n        model.add_constraint(x[i+1,j+1,board[i][j]] == 1)'
  const code_return = '  return model\n' 
  const code_main = 'if __name__ == "__main__":\n  board = [[..],[..],...,[..]]\n  \n  model = build_model(board)\n  s = model.solve()\n\n  solvedBoard = []\n  if s:\n    for i in range(1,10):\n      row = []\n      for j in range(1,10):\n        for k in range(1,10):\n          if(s.get_value(model.x[i,j,k]) == 1):\n            row.append(k)  \n      solvedBoard.append(row)\n      \n  print(solvedBoard)'

  

  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '290px',
    margin: `${size.spacing['l']} auto`, 
  };
  const sliderStyle ={ 
    display: 'flex',
    justifyContent: 'center',
  }
  

  return(
  <>
    <ContentNav items={sudokuContent[language]['contentNavItems']} theme={theme} width={width}/>
    <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
      <div id="scroll-container" style={{margin: size.spacing['s']}}>
        <section id={sudokuContent[language]['contentNavItems'][0]}>
          <BlogHeading title={sudokuContent[language]['title']} info={sudokuContent[language]['infoPills']}  theme={theme}/>
          <Paragraph theme={theme} >{sudokuContent[language]['introSudoku']}</Paragraph>\
          <div style={sliderStyle}>
            <Slider value={p} setValue={setP} label={sudokuContent[language]['emptyCells']} width={'290px'} min={10} max={90} step={10} unit='%' theme={theme}/>
          </div>
          <SudokuBoard board={board} solvedBoard={solvedBoard} theme={theme}/>
          <div style={btn_rowStyle}>
            <Button emphasis={'high'} onClick={handleNewBoardBtn} theme={theme} minWidth={size.spacing['xxl']}>{languageDict[language]['newSudoku']}</Button>
            <Button emphasis={'high'} onClick={handleSolveBtn} theme={theme} minWidth={size.spacing['xxl']}>{isLoading ? <Spinner size={"20px"}/>:languageDict[language]['solve']}</Button>
          </div>
          <SectionHeading title={'Code'} theme={theme} font_size={'l'}/>  
          <Paragraph theme={theme}>{sudokuContent[language]['introCode']}</Paragraph>
          <Paragraph theme={theme}>{sudokuContent[language]['init']}</Paragraph>
          <CodeSnippet theme={theme} title={'init'} codeString={code_init} language={'python'}/>
        </section>
        <section id={sudokuContent[language]['contentNavItems'][1]}>
          <Paragraph theme={theme}>{sudokuContent[language]['introVars']}</Paragraph>
          <Paragraph theme={theme}>{sudokuContent[language]['vars']}</Paragraph>
          <Paragraph theme={theme}>{sudokuContent[language]['varsExplanation']}</Paragraph>
          <CodeSnippet theme={theme} title={'vars'} codeString={code_vars} language={'python'}/>
        </section>
        <section id={sudokuContent[language]['contentNavItems'][2]}>
          
          <Paragraph theme={theme}>{sudokuContent[language]['introConstr']}</Paragraph>
          <Paragraph theme={theme}>{sudokuContent[language]['constr_1']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_1} language={'python'}/>
          <Paragraph theme={theme}>{sudokuContent[language]['constr_2']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_2} language={'python'}/>
          <Paragraph theme={theme}>{sudokuContent[language]['constr_3']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_3} language={'python'}/>
          <Paragraph theme={theme}>{sudokuContent[language]['constr_4']}</Paragraph>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_4} language={'python'}/>
          <Paragraph theme={theme}>{sudokuContent[language]['constr_5']}</Paragraph>
          <IconCard type={'info'} theme={theme}>{sudokuContent[language]['boardInfo']}</IconCard>
          <CodeSnippet theme={theme} title={'constraint'} codeString={code_constr_5} language={'python'}/>
          <Paragraph theme={theme}>{sudokuContent[language]['return']}</Paragraph>
          <CodeSnippet theme={theme} title={''} codeString={code_return} language={'python'}/>
          <IconCard type={'warning'} theme={theme}>{sudokuContent[language]['costWarning']}</IconCard>
        </section>
        <section id={sudokuContent[language]['contentNavItems'][3]}>
          <Paragraph theme={theme}>{sudokuContent[language]['main']}</Paragraph>
          <CodeSnippet theme={theme} title={'main'} codeString={code_main} language={'python'}/>
        </section>
      </div>
      
    </HorizontalCenter>
  </>
  )
  


}




const SudokuPage = () => {
  return (
    <PageFrame title={'Sudoku'} navType={'title'}>
      <Sudoku/>
    </PageFrame>
  );
}

export default SudokuPage;
