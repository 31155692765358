import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label
} from "recharts";

import size from '../../DesignSystem/size.json';


const plot = {
  xLabel: {
    name:'xLabel',
    offset: -8
  },
  yLabel: {
    name:'yLabel',
    offset: 15
  },
  xUnit: 'x',
  yUnit: 'y',

  lines: [
    {
      name:'y1',
      color:'red',
    },{
      name:'y2',
      color:'green',
    }
  ],

  data: [
    {
      x: 1,
      y1: 2400,
      y2: 4300,
    },
    {
      x: 2,
      y1: 1398,
      y2: 2400,
    },
    {
      x: 3,
      y1: 9800,
      y2: 1398,
    },
    {
      x: 4,
      y1: 3908,
      y2: 9800,
    },
    {
      x: 5,
      y1: 4800,
      y2: 3908,
    },
    {
      x: 6,
      y1: 3800,
      y2: 4800,
    },
    {
      x: 7,
      y1: 4300,
      y2: 3800,
    }
  ],
  margin: {
    top: 20,
    right: 20,
    left: 20,
    bottom: 20
  },
  legend: {}
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const Lines = ({width,plot}) => {



  return(
      <LineChart
        width={Math.min(width-(2*size.spacing['m'].substring(0,size.spacing['m'].length-2)),size.MAX_WIDTH_BLOG.substring(0,size.MAX_WIDTH_BLOG.length-2))}
        height={400}
        data={plot.data}
        margin={plot.margin}
      >
      <XAxis dataKey="x" unit={plot.xUnit}>
        <Label value={plot.xLabel.name} offset={plot.xLabel.offset} position="insideBottom" style={{ textAnchor: 'middle' }}/>
      </XAxis>
      <YAxis unit={plot.yUnit}>
        <Label value={plot.yLabel.name} offset={plot.yLabel.offset} angle={270} position='left' style={{ textAnchor: 'middle' }}/>    
      </YAxis>
      <Tooltip/>
      {!isEmpty(plot.legend) && <Legend wrapperStyle={plot.legend.position}/>}
      {plot.lines.map((line,i) => {
        return(
          <Line
            key={i}
            type="monotone"
            dataKey={line.name}
            stroke={line.color}
            activeDot={{ r: 1 }}
          />
        )
      })}
      
    </LineChart>
  )
}

export default Lines