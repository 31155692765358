const ScrollProgressNav = ({theme,scrollProgressY}) => {

  const outerBarStyle = {
     position: 'fixed',
     top: '60px',
     left: '0px',
     width: '100%',
     height: '2px',
     backgroundColor: 'white',
     zIndex: "9998",
  }
  const innerBarStyle = {
    width: `${scrollProgressY*100}%`,
    height: "100%",
    backgroundColor: theme.primary
  }
   
 
   return (
     <div style={outerBarStyle}>
       <div
         style={innerBarStyle}
       ></div>
     </div>
   )
 }

 export default ScrollProgressNav;