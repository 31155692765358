import "./SudokuBoard.css"

import size from '../../DesignSystem/size.json';

const SudokuRow = ({row,theme}) => {
  const cellStyle = {
    color: theme.text['2'],
    // maxHeight:"30px",
    // maxWidth:"30px",
  }
  
  return(
    <tr>
    {
      row.map((cell,j) => {
        if(cell===0){
          return<td style={cellStyle} key={j}> </td>
        }else{
          return <td style={cellStyle} key={j}> {cell} </td>
        }
      })
    }
    </tr>
  )
}

const SolvedSudokuRow = ({solvedRow,initRow,theme}) => {
  const cellStyle = {
    margin: 0,
    padding: 0,
    color: theme.text['2'],
  }


  return(
    <tr>
    {
      initRow.map((cell,j) => {
        if(cell===0){
        return<td style={{...cellStyle,...{backgroundColor:theme.primary,}}} key={j}> {solvedRow[j]}  </td>
        }else{
          return <td style={cellStyle} key={j}> {cell} </td>
        }
      })
    }
    </tr>
  )
}

const SudokuBoard = ({board,solvedBoard,theme}) => {

  const boardStyle = {
    margin: size.spacingTopBottom['l'] ,
  }

  if(solvedBoard){
    return(
      <div style={boardStyle}>
      <table>
        <tbody>
        {
          solvedBoard.map((row,i) => {
              return <SolvedSudokuRow solvedRow={row} initRow={board[i]} theme={theme} key={i}/>
          })
        }
        </tbody>
      </table>
      </div>
    )
  }else{
    return(
      <div style={boardStyle}>
      <table>
        <tbody>
        {
          board.map((row,i) => {
              return <SudokuRow row={row} theme={theme} key={i}/>
          })
        }
        </tbody>
      </table>
      </div>
    )
  }
};

export default SudokuBoard;