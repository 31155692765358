import { useState } from "react"; 

import drawService from "../services/draw"
import mailService from "../services/mail";

import size from '../DesignSystem/size.json';
import languageDict from '../Content/languageDict.json'

import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import Spinner from "../components/Animations/Spinner/Spinner";
import Button from '../components/Button/Button';
import PageFrame from '../components/PageFrame/PageFrame';
import DrawCanvas from '../components/DrawCanvas/DrawCanvas';



const Malstube = ({theme,user,language,width,addAlert}) => {
  const [lines, setLines] = useState([]);
  const [isLoading ,setIsLoading]= useState(false);

  const canvas_size = Math.min(width-48,500)

  const handleResetBtn = () => {
    setLines([])
  }
  const handleSolveBtn = async () => {
    try{
      if(!isLoading) {
        setIsLoading(true)
        const s = await drawService.save(lines,canvas_size,canvas_size)
        addAlert({type:'success','message':'Painting successfully saved'})
      } 
    } catch (error) {
      addAlert({type:'error','message':'Error while saving the painting'})
    }
    try{
      if(!isLoading) {
        const response = await mailService.sendMail({
          mail: "Malstube",
          text: 'neues Bild',
          username: user ? user.username : null
        })
      } 
    } catch (error) {
      addAlert({type:'error','message':'Error while saving the painting'})
    }
    setIsLoading(false)
  }

  const interactiveStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: size.spacingTopBottom['l']
  }
  const btn_rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: canvas_size,
    margin: `${size.spacing['l']} auto`, 
    marginBottom: '0px',
  };

 
  return(
    <>
      <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
        <div style={{margin: size.spacing['s']}}>
            <BlogHeading title={''} info={[]}  theme={theme}/>
            <div style={interactiveStyle}>
              <DrawCanvas theme={theme} canvas_size={canvas_size} lines={lines} setLines={setLines}/>
              <div style={btn_rowStyle}>
                <Button emphasis={'high'} onClick={handleResetBtn} theme={theme} minWidth={size.spacing['xxl']}>{'reset'}</Button>
                <Button emphasis={'high'} onClick={handleSolveBtn} theme={theme} minWidth={size.spacing['xxl']}>{isLoading ? <Spinner size={"20px"}/>:languageDict[language]['send']}</Button>
              </div>
            </div>
            
        </div>
      </HorizontalCenter>
    </>
    )
  } 


const MalstubePage = () => {
  return (
    <PageFrame title={'Malstube'} navType={'title'}>
      <Malstube/>
    </PageFrame>
  );
}

export default MalstubePage;
