

import PageFrame from '../components/PageFrame/PageFrame';
import IconBtn from '../components/Button/IconButton';
import LogInOut from '../components/Animations/Icons/LogInOut';
import HorizontalCenter from '../components/Helper/Center';






const Test = ({theme,width}) => {

  return(
    <></>
  )
}

const TestPage = () => {
  

  return (
    <PageFrame> 
       <Test/>
    </PageFrame>

  );
}

export default TestPage;
