
import { Send } from 'react-feather';
import { HashLink as Link } from 'react-router-hash-link';

import HorizontalCenter from "../../Helper/Center";
import SectionHeading from "../../Headings/SectionHeading";
import ContactForm from "./ContactForm";
import MagicText from '../../Animations/MagicText.js'



import homeContent from '../../../Content/homeContent.json'
import size from '../../../DesignSystem/size.json';

const Contact = ({theme, language,user,addAlert}) => {

  const contactStyle = {
    margin: size.spacing['l'],
  }
  const magicStyle = {
    position: 'relative',
    top: '-20px',
    cursor: 'pointer',
  }

  const paintSentence = {
    'GER': 'oder male ein Bild',
    'ENG': 'or paint a picture'
  }

  return( 
    <HorizontalCenter maxWidth={size.MAX_WIDTH}> 
    
    <div style={contactStyle} id='contact'>
      <SectionHeading title={homeContent[language]['contact']} theme={theme} font_size={'xl'}/>
      <div style={magicStyle}>
        <Link to={'/malstube'}>
          <MagicText text={paintSentence[language]}/>
        </Link>
      </div>
      <ContactForm theme={theme} language={language} user={user} addAlert={addAlert}/>
      
    </div>
    </HorizontalCenter>
  )
}



export default Contact;
