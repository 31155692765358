/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
const baseUrl = '/api/python/sprp_mep'

const solve = async (items,depots,nCols,nRows) => {
  const payload = { items, depots, nCols, nRows }
  const response = await axios.post(baseUrl, payload);
  return response
}

export default { solve }