import { useState, useEffect } from 'react'

import size from '../../../DesignSystem/size.json';

import PortfolioCard from '../../Cards/PortfolioCard';

const gridStyles = {
  col3: {
    display: 'grid',
    gridGap: size.spacing['l'],
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  col2: {
    display: 'grid',
    gridGap: size.spacing['l'],
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  col1: {
    display: 'grid',
    gridGap: size.spacing['l'],
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
};

const CardGrid = ({cards,theme}) => {
  const [grid, setGrid] = useState('col3');

  const handleResize = () => {
    if (window.innerWidth < 800) {
      setGrid('col1');
    } else if (window.innerWidth < 1200) {
      setGrid('col2');
    } else {
      setGrid('col3');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={gridStyles[grid]}>
      {cards.map((card, index) => {
        let cardStyle = {};
        if (grid === 'col3') {
          if (index % 3 === 0) {
            cardStyle.justifySelf = 'start';
          } else if (index % 3 === 1) {
            cardStyle.justifySelf = 'center';
          } else if (index % 3 === 2) {
            cardStyle.justifySelf = 'end';
          }
        } else {
          cardStyle.margin = '0 auto';
        }
        return (
          <PortfolioCard key={index} image={card.image} title={card.title} supTitle={card.supTitle} link={card.link} style={cardStyle} theme={theme}/>
        );
      })}
    </div>
  );
};

export default CardGrid;