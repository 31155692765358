import { useState } from 'react'

import { Copy,Check } from 'react-feather';

import size from '../../DesignSystem/size.json';

const CopyButton = ({theme,text}) => {

  const [isHovered, setHovered] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);

  const onClick = () => {
    navigator.clipboard.writeText(text);
    setSuccessful(true);
    setTimeout(() => {
      setSuccessful(false);
    }, 3000);
  }

  const copyBtnStyle = {
    height: size.iconSize['m'],
    width: size.iconSize['m'],
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: theme.bg_variation,//theme.bg_variation,
    color: isSuccessful ? theme.success : isHovered ? theme.text['1'] : theme.text['3'],
    border: isSuccessful ? `1px solid ${theme.success}` : 'transparent',
    cursor: isSuccessful ?  'default' : 'pointer' ,
    borderRadius: '4px',
    boxSizing: 'border-box',
  }

  return(
    <button 
          style={copyBtnStyle}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onFocus={() => setHovered(true)}
          onBlur={() => setHovered(false)}
          onClick={onClick}
        > 
          {!isSuccessful && <Copy/>}
          {isSuccessful && <Check/>}
          
        </button>
  )
}
export default CopyButton;