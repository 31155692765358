const Logo = ({theme,size}) => {
  const wrapperStyle = {
    height: '80px',
    widht: '80px',
  }
  return(
    // <div style={wrapperStyle}>
    <svg
      version="1.1"
      id="svg2"
      width={size}
      height={size}
      viewBox="0 0 1170.7373 1170.7373"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs
        id="defs6">
        <clipPath
          clipPathUnits="userSpaceOnUse"
          id="clipPath18">
          <path
            d="M 0,878.053 H 878.053 V 0 H 0 Z"
            id="path16" />
        </clipPath>
      </defs>
      <g
        id="g10"
        transform="matrix(1.3333333,0,0,-1.3333333,0,1170.7373)">
      <g
        id="g12">
      <g
        id="g14"
        clipPath="url(#clipPath18)">
      <g
        id="g20"
        transform="translate(367.7028,284.9501)">
        <path
          d="m 0,0 c -8.579,-11.773 -18.9,-25.869 -30.33,-41.416 -25.366,11.086 -41.89,21.988 -51.763,29.696 l 26.713,35.873 c 0,0 0.554,0.607 1.558,1.768 11.166,-7.074 22.753,-13.62 34.764,-19.533 0,0 7.048,-3.353 19.058,-6.388"
          fill={theme.text['2']}
          fillOpacity={1}
          stroke='none'
          fillRule='nonzero'
          id="path22" />
      </g>
      <g
        id="g24"
        transform="translate(337.3733,243.534)">
        <path
          d="m 0,0 c -69.634,-94.816 -179.997,-243.534 -179.997,-243.534 v 100.887 L -51.763,29.696 C -41.891,21.988 -25.367,11.087 0,0"  
          fill={theme.text['1']}
          fillOpacity={1}
          stroke='none'
          fillRule='nonzero'
          id="path26" />
      </g>
      <g
        id="g28"
        transform="translate(346.6385,354.5576)">
        <path
          d="m 0,0 c -38.354,21.83 -69.898,57.755 -82.753,71.957 -22.595,25.024 -114.587,132.748 -152.519,268.346 0,0 178.361,-154.947 209.034,-184.009 C 4.408,127.231 22.991,110.284 26.212,68.341 27.716,48.622 14.465,22.331 0,0 M 283.234,316.494 C 238.466,313.669 197.287,286.639 183.983,243.059 170.653,199.478 170.653,138.133 167.433,108.278 164.212,78.424 167.723,26.37 94.13,-9.556 81.776,-15.574 69.238,-18.002 56.779,-17.712 79.242,20.009 83.096,42.709 83.096,42.709 99.436,134.094 21.355,191.401 21.355,191.401 l -62.929,55.696 -305.064,276.398 c 0,0 20.985,-140.429 105.717,-322.011 0,0 72.987,-159.751 208.163,-245.17 11.166,-7.074 22.754,-13.621 34.764,-19.534 0,0 7.048,-3.352 19.058,-6.388 23.625,-5.912 66.546,-10.558 112.872,14.307 42.788,22.965 72.669,63.933 84.495,111.024 3.669,14.597 6.414,31.227 6.916,49.335 0,0 2.613,97.641 19.56,130.319 0,0 8.077,22.2 50.047,22.596 h 0.396 v 58.178 c -4.777,0.422 -8.869,0.554 -12.116,0.343"
          fill={theme.text['1']}
          fillOpacity={1}
          stroke='none'
          fillRule='nonzero'
          id="path30" />
      </g>
      <g
        id="g32"
        transform="translate(878.0526,612.5303)">
        <path
          d="M 0,0 C 0,0 -193.275,0.422 -236.064,0 V 58.178 C -174.956,52.767 0,0 0,0"
          //  style="fill:#d46627;fill-opacity:1;fill-rule:nonzero;stroke:none"
          fill={'#d46627'}
          fillOpacity={1}
          stroke='none'
          fillRule='nonzero'
          id="path34" />
        </g>
      </g>
    </g>
  </g>
</svg>
// </div>
  )
}

export default Logo