import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DesignPage from './pages/DesignPage';
import SudokuPage from './pages/SudokuPage';
import TestPage from './pages/TestPage';
import HomePage from './pages/HomePage'; 
import SwaggerPage from './pages/SwaggerPage';
import PMedianPage from './pages/PMedianPage';
import TSPPage from './pages/TSPPage';
import WahlkreisPage from './pages/WahlkreisPage';
import StundenplanPage from './pages/StundenplanPage';
import SPRPmepPage from './pages/SPRPmepPage';
import ImprintPage from './pages/ImprintPage';
import CertificatesPage from './pages/CertificatesPage';
import MalstubePage from './pages/MalstubePage';
import HeatGridPage from './pages/HeatGridPage';

import './index.css'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/sudoku" element={<SudokuPage/>} />
        <Route path="/pMedian" element={<PMedianPage/>} />
        <Route path="/tsp" element={<TSPPage/>} />
        <Route path="/Wahlkreise" element={<WahlkreisPage/>} />
        <Route path="/Stundenplan" element={<StundenplanPage/>} />
        <Route path="/sprpmed" element={<SPRPmepPage/>} />
        <Route path="/heatgrid" element={<HeatGridPage/>} />

        <Route path="/SWAGGER" element={<SwaggerPage />} />
        
        <Route path="/test" element={<TestPage/>} /> 
        <Route path="/imprint" element={<ImprintPage/>} />
        <Route path="/certificates" element={<CertificatesPage/>} />
        <Route path="/malstube" element={<MalstubePage/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


