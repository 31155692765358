import { motion } from "framer-motion"
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import size from '../../DesignSystem/size.json';


const Button = ({theme,children,emphasis,status='enabled',onClick,link,newTab=false,type='button',minWidth='0px'}) => {

  const btnColor = status!=='disabled' ? theme.primary : 'grey'//Token
  
  const btnStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: emphasis==='low' ? '0px': size.spacing["s"], 
    // margin: size.spacing["s"], 
    minWidth: minWidth,
    backgroundColor: emphasis==='high' ? btnColor : 'transparent',
    color: emphasis!=='high' ? btnColor : 'white',
    borderRadius: '4px', //Token
    border: 'transparent',
    boxShadow: emphasis==='medium' ? '0px 0px 0px 1px inset' : 'none', //Token
    fontSize: size.font_size["s"],

    cursor: status!=='disabled' ? 'pointer' : 'not-allowed'
  }

  if(status==='disabled'){
    return(
      <button style={btnStyle}>{children}</button>
    )
  }

  if(newTab){
    return(
      <Link to={link} target="_blank">
      <motion.button
        style={btnStyle}
        whileHover={{ scale: 1.08 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        // type={type} 
      >
        {children}
      </motion.button>
      </Link>
    )
  }
  if(type==='submit') {
    return(
      // <HashLink to={link}>
      <motion.button
        style={btnStyle}
        whileHover={{ scale: 1.08 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        type={type} 
      >
        {children}
      </motion.button>
      // </HashLink>
    )
  }
      
  return(
    <HashLink to={link}>
    <motion.button
      style={btnStyle}
      whileHover={{ scale: 1.08 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      type={type} 
    >
      {children}
    </motion.button>
    </HashLink>
  )
}

export default Button;


// eslint-disable-next-line no-lone-blocks
{/* <Button emphasis={'high'} theme={theme}> SEND </Button>
    <Button emphasis={'medium'} theme={theme}> SEND </Button>
    <Button emphasis={'low'} theme={theme}> SEND </Button>
    <Button emphasis={'high'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button emphasis={'medium'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button emphasis={'low'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'high'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'medium'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'low'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
  
    <Button emphasis={'high'} theme={theme}> SEND </Button>
    <Button emphasis={'medium'} theme={theme}> SEND </Button>
    <Button emphasis={'low'} theme={theme}> SEND </Button>
    <Button emphasis={'high'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button emphasis={'medium'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button emphasis={'low'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'high'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'medium'} theme={theme}> <Send size={'0.8em'}/> SEND </Button>
    <Button status={'disabled'} emphasis={'low'} theme={theme}> <Send size={'0.8em'}/> SEND </Button> */}