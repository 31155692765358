import { useState } from 'react'
import { motion } from "framer-motion";
import { X } from 'react-feather'; //

import size from '../../DesignSystem/size.json';


const Alert = ({ theme, message, type, removeAlert, id }) => {
  const [exit, setExit] = useState(false);

  const handleClose = () => {
    setExit(true);
    setTimeout(() => {
      removeAlert(id);
    }, 600);
  };

  const alertStyle = {
    backgroundColor: `${theme[type]}BB`,
    width: "300px",
    padding: size.spacing["m"],
    margin: size.spacing["s"],
    borderLeft: `5px solid ${theme[type]}`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const iconStyle = {
    border: `1px solid black`,
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    cursor: "pointer"
  };

  return (
    <motion.div
      style={alertStyle}
      initial={{ x: 0 }}
      animate={{ x: exit ? 320 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <div>{message}</div>
      <button style={iconStyle} onClick={handleClose}>
        <X />
      </button>
    </motion.div>
  );
};


export default Alert