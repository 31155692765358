import React from 'react';
import { ChevronsUp } from 'react-feather';
import size from '../../DesignSystem/size.json';
import { motion } from 'framer-motion';

const TopScrollBtn = ({theme, scrollProgressY}) => {

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const btnStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '100px',
    right: '25px',
    zIndex: 10000,

    backgroundColor: `${theme.primary}55`,
    color: theme.primary,
    borderRadius: '40px',
    border: 'transparent',
  }

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return(
    <motion.button
      onClick={scrollUp}
      style={btnStyle}
      initial="hidden"
      animate={scrollProgressY > 0.1 ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.3 }}
    >
      <ChevronsUp size={size.iconSize['l']}/>
    </motion.button>
  )
}

export default TopScrollBtn;
