

import size from '../../DesignSystem/size.json';

import Alert from './Alert';

const AlertMessages = ({theme,alerts,removeAlert}) => {
  const alertDivStyle = {
    // border: '1px solid red',
    position: 'fixed',
    top: size['nav']['height'],
    right: 0,
    // minWidth: '100px',
    zIndex: '10000',
  }

  return(
    <div style={alertDivStyle}>
      {alerts.slice(-2).map((item, i) => {
        return(
          <Alert theme={theme} message={item.message} type={item.type} removeAlert={removeAlert} id={item.id} key={item.id}/>
        )
        })
      }
    </div>
  )
}

export default AlertMessages