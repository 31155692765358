import { useState,useEffect,Children,cloneElement } from 'react'
import { useScroll,useMotionValueEvent } from "framer-motion";


import loginService from '../../services/login'
import size from '../../DesignSystem/size.json';
import colors from '../../DesignSystem/colors.json';

import Navbar from '../../components/Navbar/Navbar'; 
import LoginModal from '../../components/Modal/LoginModal';
import Footer from '../../components/Footer/Footer'
import AlertMessages from '../Alerts/AlertMessages';
import TopScrollBtn from '../Button/TopScrollBtn';

const PageFrame = ({children,title,navType}) => {

  const [themeName, setThemeName] = useState('dark');
  const [user, setUser] = useState('');
  const [language,setLanguage] = useState('GER') //GER||ENG
  const [loginModalIsOpen, setLoginModalOpen] = useState(false);
  const [scrollProgressY, setScrollProgressY] = useState(0)
  const [width, setWidth] = useState();
  const [alerts, setAlerts] = useState([])
  

  // console.log(alerts);
  //cookies
  useEffect( () => {
    const loggedUserJSON = window.localStorage.getItem('loggedUser')
    if (loggedUserJSON) {
      const loggedUser = JSON.parse(loggedUserJSON)

      const verifyToken = async()=> {
        const user = await loginService.verify(loggedUser)
   
        return user
      }  
      setUser(verifyToken());
    };
    const themeJSON = window.localStorage.getItem('theme')
    if (themeJSON) {
      const theme = JSON.parse(themeJSON);
      setThemeName(theme);
    };
    const languageJSON = window.localStorage.getItem('language')
    if (languageJSON) {
      const language = JSON.parse(languageJSON);
      setLanguage(language);
    };
  }, [])

  //width
  let resizeWindow = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  //Y scroll
  const { scrollYProgress  } = useScroll();
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    setScrollProgressY(latest)
  })

  //Login
  const handleLogInOut = () => {
    if(user) {
      setUser(null)
      window.localStorage.removeItem('loggedUser')
    } else {
      setLoginModalOpen(!loginModalIsOpen)
    }
  }
  const toggleLoginModalOpen = () => {
    setLoginModalOpen(!loginModalIsOpen)
  }

  //Alerts
  const removeAlert = (id) => {
    let filteredList = alerts.filter(item => item.id !== id)
    setAlerts(filteredList);
  }
  const removeAlertsByMessage = (message) => {
    let filteredList = alerts.filter(item => item.message !== message)
    setAlerts(filteredList);
  }
  const addAlert = (alert) => {
    const newID = Math.max(...alerts.map(alert => alert.id),0)+1
    // console.log(`add ${newID}`);
    const newAlert = {
      message: alert.message,
      type: alert.type,
      id: newID
    }
    setAlerts([...alerts,newAlert])
    return newAlert
  }

  //Vererbung von Props
  const renderChildren = () => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        theme: theme,
        user: user,
        language: language,
        width: width,
        addAlert: addAlert,
        removeAlert: removeAlert,
        toggleLoginModalOpen: toggleLoginModalOpen,
      });
    });
  };


  var theme = colors[themeName]

  const bodyStyle = {
    fontFamily: 'Roboto Mono',
    padding: size.spacing['m'],
    fontSize: size.font_size['s'],
    backgroundColor: theme.bg,
    paddingTop: '100px'
  } 

  return(
    <div style={bodyStyle}>
      <Navbar theme={theme} type={navType} title={title} themeName={themeName} setThemeName={setThemeName} scrollProgressY={scrollProgressY} user={user} handleLogInOut={handleLogInOut} language={language} setLanguage={setLanguage} width={width}/>
      <TopScrollBtn theme={theme} scrollProgressY={scrollProgressY}/>
      <LoginModal theme={theme} loginModalIsOpen={loginModalIsOpen}  toggleLoginModalOpen={toggleLoginModalOpen} setUser={setUser} width={width} addAlert={addAlert} language={language}/>
      <AlertMessages theme={theme} alerts={alerts} removeAlert={removeAlert}/>
      {renderChildren()}
      <Footer theme={theme} width={width} language={language}/>

    </div>
  )
}

export default PageFrame;