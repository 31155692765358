

import size from '../../../DesignSystem/size.json';

import HorizontalCenter from "../../Helper/Center";
import SectionHeading from '../../Headings/SectionHeading';
import ProgressLine from "../../ProgressLine/ProgressLine";


const Skills = ({ theme,width }) => {


  const skillsStyle = {
    display: "flex",
    flexDirection: width>1200 ? 'row' :  "column",
    gap: width>1200 ? '50px' :  "0px",
    justifyContent: "space-between",
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth : width>1200 ? '45%' :  0,
  };

  return (
    <HorizontalCenter maxWidth={size.MAX_WIDTH}>
      <div style={{margin: size.spacing['l']}}>
        <SectionHeading title={"Skills"} theme={theme} font_size={"xl"} />
        <div style={skillsStyle}>
          <div style={columnStyle}>
            <ProgressLine label="Python" theme={theme} percentage={90} />
            <ProgressLine label="Julia" theme={theme} percentage={55} />
            <ProgressLine label="JavaScript/TypeScript" theme={theme} percentage={65} />
          </div>
          <div style={columnStyle}>
            <ProgressLine label="Java" theme={theme} percentage={20} />
            <ProgressLine label="SQL" theme={theme} percentage={30} />
            <ProgressLine label="(HTML/CSS)" theme={theme} percentage={50} />
          </div>
        </div>
        {/* JumpTarget */}
        <span id='cv'></span>
      </div>
    </HorizontalCenter>
    
  );
};

export default Skills;