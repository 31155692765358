import { useState } from "react"; //useEffect
import { motion } from "framer-motion"
import Scrollspy from "react-scrollspy-highlight";
import { HashLink as Link } from 'react-router-hash-link';

import ArrowIcon from "../Animations/Icons/ArrowIcon";

import size from '../../DesignSystem/size.json';

const ContentNav = ({theme,items,width,offset=-70}) => {
  const [isOpen, setIsOpen] = useState(true);


  let mobileView = false
  if(width < 1300){
    mobileView = true
  }

  
  const navStyle = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.text['2'],
    paddingLeft: size.spacing['m'],
    zIndex: 2000,
  };
  const buttonStyle = {
    alignSelf: 'center',
    backgroundColor: 'transparent',
    border: 'transparent',
    padding: '0',
    marginLeft: size.spacing['m'],
    zIndex: 2000,
  };
  const linkStyle = {
    cursor: 'pointer',
  }

  const variants = {
    closed: { x:`calc(-100% + ${size.iconSize['l']} + ${size.spacing['s']})` },
    open: { x: 0 }
  }



  return(
    <>
    { (!mobileView) && (
      <motion.div style={navStyle}
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      transition={{ duration: 1, type: "spring" }}
      >
        <Scrollspy 
          items={ items } 
          currentClassName="is-current"
          componentTag='div'
          offset={offset}
        >
          {items.map((item, i) => { 
            return (
            <div key={i}>
              <Link to={`./#${item}`} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, offset) }}>
                <span style={linkStyle}>{item}</span>
              </Link>
            </div>) 
          })}
        </Scrollspy>
        <button style={buttonStyle} onClick={() => setIsOpen(!isOpen)}>
          <ArrowIcon size={size.iconSize['l']} theme={theme}/>
        </button>
      </motion.div>
    )}
    </>
  )
}

export default ContentNav;