import size from '../DesignSystem/size.json';
import certificateContent from '../Content/certificateContent.json'

import lebenslauf_pdf from "../PDFs/Lebenslauf.pdf";
import notenspiegelBA_pdf from "../PDFs/Notenspiegel_Bachelor.pdf"
import notenspiegelMA_pdf from "../PDFs/Notenspiegel_Master.pdf"
import immatrikulationsbescheinigung_pdf from "../PDFs/Immatrikulationsbescheinigung.pdf";
import abitur_pdf from "../PDFs/AbiturZeugnis.pdf"
import ml_pdf from "../PDFs/ML-Zertifikat.png"

import HorizontalCenter from '../components/Helper/Center';
import BlogHeading from '../components/Headings/BlogHeading';
import CertificateItem from '../components/CertificateItem/CertificateItem';
import Paragraph from '../components/Text/Paragraph';
import Button from '../components/Button/Button';
import PageFrame from '../components/PageFrame/PageFrame';



const Certificates = ({theme,user,language,toggleLoginModalOpen}) => {

  const logInSentence = {
    'GER': 'Bitte einloggen, um die Zeugnisse sehen zu können.',
    'ENG': 'Please log in to view the certificates.'
  }

  if(user !== ''){
    return(
    <>
      <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
        <div style={{margin: size.spacing['s']}}>
            <BlogHeading title={'Download'} info={[]}  theme={theme}/>
            <CertificateItem theme={theme} title={certificateContent[language]["CV"].title} pdf={lebenslauf_pdf} language={language}/>
            <CertificateItem theme={theme} title={certificateContent[language]["Bachelor"].title} pdf={notenspiegelBA_pdf} language={language}/>
            <CertificateItem theme={theme} title={certificateContent[language]["Master"].title} pdf={notenspiegelMA_pdf} language={language}/>
            <CertificateItem theme={theme} title={certificateContent[language]["Uni"].title} pdf={immatrikulationsbescheinigung_pdf} language={language}/>
            <CertificateItem theme={theme} title={certificateContent[language]["Abitur"].title} pdf={abitur_pdf} language={language}/>
            <CertificateItem theme={theme} title={certificateContent[language]["Coursera"].title} pdf={ml_pdf} language={language}/>
        </div>
      </HorizontalCenter>
    </>
    )
  } else{
    return(
      <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
        <div style={{margin: size.spacing['s'],marginBottom:'70vh'}}>
          <BlogHeading title={'Download'} info={[]}  theme={theme}/>
          <Paragraph theme={theme} >{logInSentence[language]}</Paragraph>
          <Button onClick={toggleLoginModalOpen} emphasis={'high'} theme={theme} minWidth={size.spacing['xxl']}>{'LogIn'}</Button>
        </div>
      </HorizontalCenter>
    )
  }
}


const CertificatesPage = () => {
  return (
    <PageFrame title={'Certificates'} navType={'title'}>
      <Certificates/>
    </PageFrame>
  );
}

export default CertificatesPage;

