import { useState } from 'react'
import { motion } from 'framer-motion';


const UnderlineHover = ({ children,color }) => {
  const [isHovered, setHovered] = useState(false);

 
  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      
      <motion.div
      initial={{ scaleX: 0 }}
      animate={{ scaleX: isHovered ? 1 : 0 }}
      transition={{ duration: 0.3 }}
      style={{
        height: '2px',
        backgroundColor: color,
        originX: isHovered ? 0 : 1,
        }}
      />
      
    </span> 
  );
};

export default UnderlineHover;