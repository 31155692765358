import { useState } from 'react'
import { ExternalLink } from 'react-feather';

import size from '../../DesignSystem/size.json';

const Link = ({children,theme,link,type}) => {
  const [isHovered, setHovered] = useState(false);

  const linkStyle = {
    cursor: 'pointer',
    color: theme.primary,
    textDecoration: isHovered ? `solid underline ${theme.primary} 2px` : 'none',

  }

  if(type==='external'){
    return(
      <span 
        style={linkStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
      > 
        <a href={link}>{children} <ExternalLink size={size.iconSize['xs']}/></a>
      </span>

    )}
  else{
    return(
      <span 
        style={linkStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
      > 
        <a href={link}>{children}</a>
      </span>
  
    )
  }
}

export default Link;