

import Button from "../Button/Button";

import size from '../../DesignSystem/size.json';
import languageDict from '../../Content/languageDict.json' 

const CertificateItem = ({theme,title,pdf,language}) => {

  const openItem = () => {
    window.open(pdf);
  }

  const wrapperStyle = {
    margin: size.spacingTopBottom['l']
  }
  const titleStyle = {
    color: theme.text['1'],
    fontSize: size.font_size['m'],
    margin: size.spacingTopBottom['s']
  }
  const btnStyle = {

  }
  
  return(
    <div style={wrapperStyle}>
      <div style={titleStyle} dangerouslySetInnerHTML= {{__html: title }}>
        {/* {title} */}
      </div>
      <div style={btnStyle}>
        <Button onClick={openItem} emphasis={'high'} theme={theme} >{languageDict[language]['open']}</Button>
      </div>
    </div>
  )
}

export default CertificateItem;