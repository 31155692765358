const Notification = ({ message, type, theme }) => {

  const notificationStyle = {
    color:theme[type]
  }

  if (message === null) {
    return(
      <div style={{color: theme.bg}}>
        {'.'}
      </div>
    )
  }

  return (
    <div style={notificationStyle}>
      {message}
    </div>
  )
  
  
}

export default Notification