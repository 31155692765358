
import size from '../../DesignSystem/size.json';

const Paragraph = ({children,theme}) => {

  const paragraphStyle = {
    color: theme.text['2'],
    fontSize: size.font_size['s'],
    marginBottom: size.spacing['s']
  }

  return(
    <p style={paragraphStyle}
      dangerouslySetInnerHTML={{ __html: children }}
    >
    </p>
  )
} 

export default Paragraph;