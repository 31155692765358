
import size from '../../DesignSystem/size.json';


const Textarea = ({theme,children,type,placeholder,setContent,required=false}) => {

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const inputStyle = {
    padding: '5px',
    border: 'none',
    width: '100%',
    height: '',
    color: theme.text['1'],
    backgroundColor: theme.bg_variation,
  };
  
  const textAreaStyle = {
    ...inputStyle,
    height: '100px',
  };

  const inputWrapperStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    margin: size.spacingTopBottom['s'],
    padding: size.spacing['s'],
    backgroundColor: theme.bg_variation,
    color: theme.text['1'],
    border: `1px solid ${theme.hairline}`,
    borderRadius: '5px',
    width: '100%',
    maxWidth: '800px'
  };

  return(
    <div style={inputWrapperStyle}>
        {children}
        <textarea type={type} style={textAreaStyle} placeholder={placeholder} onChange={handleContentChange} required={required}/>
    </div>
  )
}

export default Textarea;