
import size from '../../../DesignSystem/size.json';
import portfolioContent from '../../../Content/portfolioContent.json'



import SectionHeading from '../../Headings/SectionHeading';
import HorizontalCenter from '../../Helper/Center';
import CardGrid from './CardGrid';

import sudokuImg from '../../../images/Portfolio/ungelöst.png';
import pMedianImg from '../../../images/Portfolio/pMedianErgebnis.png';
import tspImg from '../../../images/Portfolio/TSP_Deutschland_3.png';
import saarlandImg from '../../../images/Portfolio/Saarland.png';
import timetableImg from '../../../images/Portfolio/Stundenplan.png';
import sprp_medImg from '../../../images/Portfolio/SPRP_med.png';
import haetgridImg from '../../../images/Portfolio/HeatGrid.png'

const Portfolio = ({theme,language}) => {


  const cards = [
    {
      title: portfolioContent[language]['heatgrid']['title'],
      supTitle: portfolioContent[language]['heatgrid']['supTitle'],
      image: haetgridImg,
      link: '/heatgrid/#',
    },
    {
      title: portfolioContent[language]['SPRP']['title'],
      supTitle: portfolioContent[language]['SPRP']['supTitle'],
      image: sprp_medImg,
      link: '/sprpmed/#',
    },
    {
      title: portfolioContent[language]['sudoku']['title'],
      supTitle: portfolioContent[language]['sudoku']['supTitle'],
      image: sudokuImg,
      link: '/sudoku/#',
    },
    {
      title: portfolioContent[language]['TSP']['title'],
      supTitle: portfolioContent[language]['TSP']['supTitle'],
      image: tspImg,
      link: '/tsp/#',
    },
    {
      title: portfolioContent[language]['pMedian']['title'],
      supTitle: portfolioContent[language]['pMedian']['supTitle'],
      image: pMedianImg,
      link: '/pMedian/#',
    },
    {
      title: portfolioContent[language]['election']['title'],
      supTitle: portfolioContent[language]['election']['supTitle'],
      image: saarlandImg,
      link: '/Wahlkreise/#',
    },
    {
      title: portfolioContent[language]['timetable']['title'],
      supTitle: portfolioContent[language]['timetable']['supTitle'],
      image: timetableImg,
      link: '/Stundenplan/#',
    },
  ]


  const portfolioStyle = {
    margin: size.spacing['l'],
    // marginTop: size.spacing['l'],
  }
  return( 
    <HorizontalCenter maxWidth={size.MAX_WIDTH}> 
    
    <div style={portfolioStyle} >
      <SectionHeading title={'Portfolio'} theme={theme} font_size={'xl'}/>
      <CardGrid cards={cards} theme={theme}/>
      <span id='skills'></span>
    </div>
    </HorizontalCenter>
  )
}


export default Portfolio;