import size from "../DesignSystem/size.json";
import languageDict from "../Content/languageDict.json";
import heatgridContent from "../Content/heatgridContent.json";

import HorizontalCenter from "../components/Helper/Center";
import BlogHeading from "../components/Headings/BlogHeading";

import Paragraph from "../components/Text/Paragraph";
import Button from "../components/Button/Button";
import PageFrame from "../components/PageFrame/PageFrame";
import List from "../components/List/List";
import { TechStack } from "../components/TechStack/TechStack";

const HeatGrid = ({ theme, user, language, width, addAlert }) => {
  const btn_rowStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: size.spacing["l"],
    marginBottom: size.spacing["xl"],
    // marginBottom: '0px',
  };

  return (
    <>
      {/* <ContentNav items={heatgridContent[language]['contentNavItems']} theme={theme} width={width}/> */}
      <HorizontalCenter maxWidth={size.MAX_WIDTH_BLOG}>
        <div id="scroll-container" style={{ margin: size.spacing["s"] }}>
          <section id={heatgridContent[language]["contentNavItems"][0]}>
            <BlogHeading
              title={heatgridContent[language]["title"]}
              info={heatgridContent[language]["infoPills"]}
              theme={theme}
            />
            <Paragraph theme={theme}>
              {heatgridContent[language]["intro"]}
            </Paragraph>
            <Paragraph theme={theme}>
              {heatgridContent[language]["intro2"]}
            </Paragraph>
            <Paragraph theme={theme}>
              {heatgridContent[language]["additionalFlexibility"]}
            </Paragraph>

            <TechStack theme={theme} />
            <Paragraph theme={theme}>
              {heatgridContent[language]["introFrame1"]}
            </Paragraph>
            <Paragraph theme={theme}>
              {heatgridContent[language]["introFrame2"]}
            </Paragraph>
            <div style={{ marginTop: size.spacing["l"] }}>
              <Paragraph theme={theme}>
                {heatgridContent[language]["credentialsIntro"]}
              </Paragraph>
              <div style={{ marginLeft: size.spacing["m"] }}>
                <Paragraph theme={theme}>
                  {heatgridContent[language]["credentials"]}
                </Paragraph>
              </div>
            </div>

            <div style={btn_rowStyle}>
              <Button
                link={"http://frame.jl-kramer.de/"}
                newTab={true}
                emphasis={"high"}
                theme={theme}
                minWidth="80px"
              >
                App
              </Button>
            </div>
          </section>
        </div>
      </HorizontalCenter>
    </>
  );
};

const HeatGridPage = () => {
  return (
    <PageFrame title={"Heatgrid"} navType={"title"}>
      <HeatGrid />
    </PageFrame>
  );
};

export default HeatGridPage;
