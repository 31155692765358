

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';

import atomDark from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import atomLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';

import CopyButton from '../Button/CopyButton';

import size from '../../DesignSystem/size.json';

//SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('js', js);



const CodeSnippet = ({codeString,title,language,theme,themeName}) => {

  let codeStyle = themeName==='dark' ? atomDark : atomLight

  codeStyle['hljs']['background'] = theme.bg_variation
  codeStyle['hljs']['color'] = theme.text['2']
  // codeStyle['font-size'] = '8px'

 
  

  const cardStyle = {
    backgroundColor : theme.bg_variation,
    borderRadius: '4px',
    paddingBottom: size.spacing['s'],
    paddingTop: title==='' ?  size.spacing['s'] : 0,
    border: `1px solid ${theme.hairline}`,
    margin: size.spacingTopBottom['m'],
    maxWidth : '100%'
    
  }
  const titleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.text['1'],
    fontSize: size.font_size['s'],
    padding: size.spacing['s'],
    borderBottom: `1px solid ${theme.hairline}`,
    margin: 0
  }
 

  return(
    <div style={cardStyle}>
      {title && 
      <div style={titleStyle}>
        {title}
        <CopyButton text={codeString} theme={theme}/>
      </div>}
      <SyntaxHighlighter language={language} style={codeStyle} >
        {codeString}
      </SyntaxHighlighter>
    </div>
  )
}

export default CodeSnippet
